import _ from 'lodash';
import { mapState } from 'vuex';
import EmptyCard from '../models/EmptyCard';
import DataCard from '../models/DataCard';
import configMixin from './configMixin';
import htmlSanitizer from './htmlSanitizer';
import multisimMixin from './multisimMixin';
import viewMixin from './viewMixin';

export default {
  mixins: [
    configMixin,
    htmlSanitizer,
    multisimMixin,
    viewMixin,
  ],
  props: {
    bantoshow: Object,
  },
  data() {
    return {
      ban: null,
      separatedGroups: {
        inGroup: [],
        notInGroup: [],
      },
    };
  },
  created() {
    this.ban = this.bantoshow;
  },
  watch: {
    bantoshow(newVal) {
      this.ban = newVal;
    },
    ban(newVal) {
      this.separatedGroups = this.separateGroups(newVal);
      this.calcHiper();
      this.pushCtnsDatalayer();
    },
  },
  computed: {
    ...mapState('config', ['limits', 'config']),
    ...mapState('multisim', ['bans', 'selectedBan', 'inGroupMembers']),

    multisimInfo() {
      return this.ban['multisim-group-info'];
    },
    postpaidLimit() {
      return this.multisimInfo ? this.multisimInfo['postpaid-voice-limit'] : 6;
    },
    internetLimit() {
      return this.multisimInfo ? this.multisimInfo['large-screen-mbi-limit'] : 1;
    },
    prepaidLimit() {
      return this.multisimInfo ? this.multisimInfo['prepaid-voice-limit'] : 3;
    },

    groups() {
      const groups = this.separatedGroups;
      return {
        inGroup: {
          prePaid: this.createItems(groups.inGroup, this.limits.MultiSimLimits.Prepaid.productCodes, this.textMemberPrep),
          postPaid: this.createItems(groups.inGroup, this.limits.MultiSimLimits.Postpaid.productCodes, this.textMemberPost),
          internet: this.createItems(groups.inGroup, this.limits.MultiSimLimits.MBI.productCodes, this.textMemberInternet),
        },
        notInGroup: {
          prePaid: this.createItems(Object.values(this.user.prepaid || {}).map(k => this.mapCTN(k)).filter(p => !_.some(groups.inGroup, { ctn: p.ctn })), this.limits.MultiSimLimits.Prepaid.productCodes, this.textNotMemberPrep),
          postPaid: this.createItems(groups.notInGroup, this.limits.MultiSimLimits.Postpaid.productCodes, this.textNotMemberPost),
          internet: this.createItems(groups.notInGroup, this.limits.MultiSimLimits.MBI.productCodes, this.textNotMemberInternet),
        },
      };
    },
    cardTextedGroups() { // Cardtext értéket a limit kezelés miatt külön kell számolni
      const result = {};
      Object.keys(this.groups).forEach((k) => {
        const group = {};
        const member = this.groups[k];
        Object.keys(member).forEach((p) => {
          group[p] = member[p].map(ctnCard => ({
            ...ctnCard,
            cardText: ctnCard.cardText(ctnCard.value),
          })).sort((a, b) => {
            const ap = a.cardText && a.cardText.priority === 0 ? a.cardText.priority : Number.MAX_SAFE_INTEGER;
            const bp = b.cardText && b.cardText.priority === 0 ? b.cardText.priority : Number.MAX_SAFE_INTEGER;
            return ap - bp;
          });
        });
        result[k] = group;
      });
      return result;
    },
    filledGroups() {
      const groups = { // listák másolása
        inGroup: {
          prePaid: [...this.cardTextedGroups.inGroup.prePaid],
          postPaid: [...this.cardTextedGroups.inGroup.postPaid],
          internet: [...this.cardTextedGroups.inGroup.internet],
        },
        notInGroup: {
          prePaid: [...this.cardTextedGroups.notInGroup.prePaid],
          postPaid: [...this.cardTextedGroups.notInGroup.postPaid],
          internet: [...this.cardTextedGroups.notInGroup.internet],
        },
      };
      return this.fillEmpty(groups);
    },
  },
  methods: {
    textMemberPrep(ctn) {
      return this.getTextCard(ctn);
    },
    textMemberPost(ctn) {
      return this.getTextCard(ctn);
    },
    textNotMemberPrep(ctn) {
      return this.getTextCard(ctn);
    },
    textNotMemberPost(ctn) {
      return this.getTextCard(ctn);
    },
    textNotMemberInternet(ctn) {
      return this.getTextCard(ctn);
    },
    textMemberInternet(ctn) {
      return this.getTextCard(ctn);
    },
    getHiperMembers(list) {
      return list.filter((item => item.value.isHiper));
    },

    responseHandler(resp) {
      const storeBan = this.storeNewBan(resp);
      this.ban = storeBan.resp;
      this.separatedGroups = this.separateGroups(this.ban);
      this.calcHiper(storeBan.resp, this.selectedBan);
    },
    calcHiper(ban, banid) {
      const inGroup = {
        hiperMembers: 0,
        totalMembers: 0,
        prepaidMembers: 0,
        postpaidMembers: 0,
        internetMembers: 0,
        hasNotInGroup: {
          prepaid: (this.groups.notInGroup.prePaid !== []),
          internet: (this.groups.notInGroup.internet !== []),
          postpaid: (this.groups.notInGroup.postPaid !== []),
        },
      };

      this.separatedGroups.inGroup.forEach((ctn) => {
        inGroup.totalMembers++;
        if (ctn.isHiper) inGroup.hiperMembers++;
        if (ctn['product-code'] === 'REGL') inGroup.postpaidMembers++;
        if (ctn['product-code'] === 'PREP' && (ctn['group-status'] === 'M' || ctn['group-status'] === 'I')) inGroup.prepaidMembers++;
        if (ctn['product-code'] === 'MBIN') inGroup.internetMembers++;
      });
      this.$store.commit('multisim/setHiperMemberSize', { ban: banid, inGroup });
    },
    separateGroups(ban) {
      const groups = {
        inGroup: [],
        notInGroup: [],
      };
      ban['multisim-member-list'].forEach((ctn) => {
        if ((ctn['group-status'] === 'M' || ctn['group-status'] === 'S' || ctn['group-status'] === 'I') && ban['multisim-group-info']['group-type'] === '411') {
          groups.inGroup.push(ctn);
        } else {
          groups.notInGroup.push(ctn);
        }
      });
      return groups;
    },

    createItems(items, filters, cardTextSuplier) {
      return items
        .filter(k => _.some(filters, p => p === k['product-code']))
        .map(k => new DataCard({ ...k }, cardTextSuplier));
    },

    getTextCard(ctn) {
      return this.createDisableText(ctn, '');
    },

    fillEmpty(item) {
      const groups = { ...item };
      const postpaid = groups.inGroup.postPaid;
      const hiperLimit = 2;
      const hiperValue = () => new EmptyCard({ message: 'Adj hozzá egy számot Yettel Prime vagy Yettel M Talk tarifacsomaggal', 'price-plan-description': 'Prime tarifacsomag', isHiper: true });
      const postpaidCount = this.getHiperMembers(postpaid).length;
      for (let i = 0; i < hiperLimit - postpaidCount; i++) {
        postpaid.push(hiperValue());
      }
      const postPaidValue = () => new EmptyCard({ message: 'Adj hozzá egy előfizetéses számot' });
      const internetValue = () => new EmptyCard({ message: 'Adj hozzá egy otthoni számot' });
      const prepaidValue = () => new EmptyCard({ message: 'Kártyás előfizetés' });
      this.fillList(postpaid, postPaidValue, this.postpaidLimit);
      this.fillList(groups.inGroup.internet, internetValue, this.internetLimit);
      this.fillPrepaid(groups.inGroup.prePaid, prepaidValue, this.prepaidLimit);
      groups.inGroup.postPaid = postpaid.sort((a, b) => b.value.isHiper - a.value.isHiper);
      return groups;
    },
    fillList(array, producer, limit) {
      for (let i = array.length; i < limit; i++) {
        array.push(producer());
      }
    },
    fillPrepaid(array, producer, limit) {
      for (let i = array.filter(pp => pp.value['group-status'] !== 'S').length; i < limit; i++) {
        array.push(producer());
      }
    },
  },
};

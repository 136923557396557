import CardText from './CardText';

export default class DisabledCardText extends CardText {
  constructor(value, messages) {
    super();
    this.value = value;
    this.messages = messages;
    this.component = 'disabled-text';
  }
}

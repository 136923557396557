<template>
<div>
  <panel v-for="(panel, key) in panels" :key="key" :label="panel.title" :value="panel.values" :expansion="true" :emptyText="panel.emptyText" :responseHandler="responseHandler"/>
  <!-- <panel label="Számlás előfizetéseim" :value="value.postPaid" :expansion="true" :responseHandler="responseHandler"/>
  <panel label="Otthoni internet" :value="value.internet" :expansion="true" :responseHandler="responseHandler"/>
  <panel v-if="!hidePrepaid" label="Feltöltőkártyás előfizetéseim" :value="value.prePaid" :expansion="true" :mobileclose="true" :responseHandler="responseHandler"/> -->
</div>
</template>

<script>
import panel from './panel.vue';

export default {
  components: {
    panel,
  },
  props: {
    panels: Array,
    responseHandler: {
      type: Function,
    },
  },
};
</script>

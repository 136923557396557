<template>
  <div class="sub-box">
    <div class="sub-box-header">
      <h3 :title="value.name">{{value.name}}</h3>
      <span :class="'c-default ' + value.colorClass">{{value['price-plan-description']}}</span>
    </div>
    <h3 :class="'box-phone-number ' + value.isGray">{{phoneNumber}}</h3>
    <slot/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import configMixin from '../../plugins/configMixin';

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      response: false,
    };
  },
  props: {
    value: Object,
    responseHandler: {
      type: Function,
    },
  },
  mixins: [configMixin],
  methods: {},
  computed: {
    ...mapState('multisim', ['bans', 'selectedBan', 'inGroupMembers']),
    ...mapState('config', ['limits', 'memberLimits']),
    phoneNumber() {
      const a = this.value.ctn;
      return `+36 ${a.slice(0, 2)} ${a.slice(2, 5)} ${a.slice(5, 9)}`;
    },
    isValidPriceplan() {
      return this.limits.MultiSimAddPostpaid.EligiblePricePlans.includes(this.value['price-plan']) || this.limits.MultiSimAddPrepaid.EligiblePricePlans.includes(this.value['price-plan']);
    },
  },
};
</script>

<style scoped>

.gray {
color: #99A7B3 !important;
}
.red {
  background-color:#FFE9D3 !important;
  color: #002340;
}
.blue {
  background-color:#CCF4FF !important;
  color: #002340;
}
.green {
  background-color:#DCFAD9 !important;
  color: #002340;
}
.yellow {
  background-color:#FFFCB3 !important;
  color: #002340;
}
</style>

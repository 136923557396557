import axios from 'axios';

export default {
  getMultisimInfo(banid) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/multisim/${banid}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  deletePostpaid(banid, ctn) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/multisim/${banid}/postp/${ctn}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  addPostpaid(banid, phone) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/multisim/${banid}/postp`, { ctn: phone }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  createGroup(banid, ctn1, ctn2) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/multisim/${banid}`, { ctn1, ctn2 }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  deletePrepaid(banid, ctn) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/multisim/${banid}/prep/${ctn}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  addPrepaid(banid, prep, postp) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/multisim/${banid}/prep`, { 'prepaid-ctn': prep, 'postpaid-ctn': postp }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  invitePrepaid(banid, prep, postp) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/multisim/${banid}`, { 'prepaid-ctn': prep, 'postpaid-ctn': postp }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  acceptInvitePrepaid(ctn) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/multisim/ctn/${ctn}`, {}).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  deleteInvitePrepaid(ctn) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/multisim/ctn/${ctn}`, {}).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  getCtnInfo(ctn) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/multisim/ctn/${ctn}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  getSalt() {
    return new Promise((resolve, reject) => {
      axios.get('/api/config/salt').then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

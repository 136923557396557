<template>
<div class="faq-container">
  <h3 class="faq-big-title">Gyakori kérdések</h3>
  <v-expansion-panels v-model="panel" class="faq-expansion-panel" accordion multiple>
    <v-expansion-panel v-for="q in config.faq" :key="q.title" >
      <v-expansion-panel-header>
        <p class="faq-title-text">{{ q.title }}</p>
        <div class="faq-icon">
          <svg width="16px" height="16px" id="faq" :class="'faq-arrow' +(panel.includes(config.faq.indexOf(q)) ? ' faq-rotate' : '')" viewBox="0 0 500 500">
            <svg viewBox="0 0 480 480">
              <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/>
            </svg>
          </svg>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="faq-expansion-content" v-html="faqSanitize(q.content)">
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
</template>

<script>
import { mapState } from 'vuex';
import htmlSanitizer from '../../plugins/htmlSanitizer';

export default {
  data() {
    return {
      panel: [],
    };
  },
  mixins: [htmlSanitizer],
  computed: {
    ...mapState('config', ['config']),
  },
};
</script>

<style>
.faq-title-plus-icon {
  flex: none !IMPORTANT;
  margin-right: 10px;
  width: 25px;
}

.faq-rotate {
  transform-origin: center;
  transform: rotate(-180deg);
  fill: #002340;
}

.faq-icon {
  flex: none !IMPORTANT;
  width: 16px;
  height: 16px;
  fill: #002340;
  margin-right: 10px;
  margin-top: 10px;
}

.faq-arrow {
  transition: all 0.3s ease-in-out 0.2s;
}

.faq-title-plus-icon::after {
  width: 30px;
  height: 20px;
  border: 1px solid #333;
  border-radius: 50%;
  text-align: center;
  line-height: calc(1.6rem - 2px);
  font-size: 1rem;
  color: #333;
  content: '+';
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.faq-title-minus-icon {
  flex: none !IMPORTANT;
  margin-right: 10px;
  width: 25px;
}

.faq-title-minus-icon::after {
  width: 30px;
  height: 20px;
  border: 1px solid #333;
  border-radius: 50%;
  text-align: center;
  line-height: calc(1.6rem - 2px);
  font-size: 1rem;
  color: #333;
  content: '-';
  padding-left: 10px;
  padding-right: 9px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.faq-title-text {
  font-size: 1.125em;
  font-family: yettel,sans-serif,arial;
  margin: 0 !IMPORTANT;
  color: #002340;
}

.faq-big-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #002340;
  font-family: yettel_bold,sans-serif,arial;
  font-weight: normal;
  font-size: 3em;
}

.faq-container {
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.faq-expansion-panel .v-expansion-panel {
  background-color: #ffffff !IMPORTANT;
}

.faq-expansion-panel .v-expansion-panel .v-expansion-panel-header .v-expansion-panel-header__icon {
  display: none;
}

.faq-expansion-panel .v-expansion-panel .v-expansion-panel-content {
  display: block;
  font-family: yettel,sans-serif,arial;
  color: #002340;
}

.faq-expansion-panel .v-expansion-panel::before {
  box-shadow: none;
}

.faq-expansion-content {
  margin-left: 60px;
}

@media (max-width: 580px) {
  .faq-expansion-content {
    margin-left: unset;
  }
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 55px !IMPORTANT;
}
</style>

<template>
  <div>
    <v-row justify="center">
      <v-dialog overlay-color="#002340" overlay-opacity="0.8" :value="enable" @input="onCancel()" max-width="600" :persistent="loading" v-if="!failed && !success">
        <a @click="onCancel()" class="dialog-x-button">
        <img  class="gray-x-svg" :src="require('../../../assets/gray-x.svg')" contain height="23px"/>
        </a>
        <v-card class="dialog-content">
          <div>
            <span class="dialog-title-text">
              <slot/>
            </span>
            <slot name="icon"/>
            <slot name="bottom"/>
          </div>
        </v-card>
        <div class="dialog-buttons">
          <v-btn :disabled="loading" class="dialog-gray-button" text @click="onCancel()">Mégsem</v-btn>
          <v-btn :disabled="loading" :class="`dialog-${buttonColor}-button`" text @click="submit()">
            {{okButtonText}}
            <img class="loading-animation dialog" v-if="loading" :src="require('../../../assets/loader-gray.gif')" contain height="15px"/>
          </v-btn>
        </div>
      </v-dialog>
    </v-row>

     <error-dialog :failed="failed" :onCancel="onCancel" :title="errorMessage" :failedMessage="errorDetail"/>
     <success-dialog :success="success" :onCancel="onCancel" :title="successMessage"/>
  </div>
</template>

<script>
import errorDialog from './error-dialog.vue';
import successDialog from './success-dialog.vue';

export default {
  components: {
    errorDialog,
    successDialog,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
    },
    cancel: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
    failed: Boolean,
    success: Boolean,
    successMessage: String,
    errorMessage: String,
    okButtonText: String,
    buttonColor: String,
    errorDetail: String,
  },
  methods: {
    onCancel() {
      if (!this.loading) {
        this.cancel();
      }
    },
  },
};
</script>

<style>

</style>

<template>
      <v-row justify="center">
        <v-dialog overlay-color="#002340" overlay-opacity="0.8" :value="failed" @input="onCancel()" max-width="600">
          <a @click="onCancel()" class="dialog-x-button">
            <img  class="gray-x-svg" :src="require('../../../assets/gray-x.svg')" contain height="23px"/>
          </a>
          <v-card class="dialog-content">
            <div v-if="failed">
              <span class="dialog-title-text bold" v-html="title"></span>
              <img  class="red-error-svg" :src="require('../../../assets/red-error.svg')" contain height="58px"/>
              <div class="errors-list-container">
                <img v-if="!!failedMessage" class="small-red-warning-svg" :src="require('../../../assets/small-red-warning.svg')" contain height="21px"/><span v-if="!!failedMessage" class="error-dialog-small-error-svg-text" v-html="failedMessage"></span>
              </div>
            </div>
          </v-card>
          <div class="dialog-buttons">
            <v-btn v-if="failed" class="dialog-green-button" text @click="onCancel()">Rendben</v-btn>
          </div>
        </v-dialog>
      </v-row>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  props: {
    failed: {
      type: Boolean,
      default: false,
    },
    failedMessage: String,
    title: String,
    onCancel: Function,
    nominators: Array,
  },
};
</script>

<style>
.error-dialog-small-error-svg-text {
  margin-left: 10px;
  vertical-align: top;
}

.errors-list-container {
  margin: auto;
  max-width: fit-content;
}

</style>

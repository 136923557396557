<template>
  <div>
    <BanSelect @menu-selected="menuSelected" :isEmptyAccount="isEmptyAccount"/>
    <div v-if="loading" class="loader-big">
      <div class="loader-container">
        <div class="loader">Loading...</div>
      </div>
    </div>
    <div class="dasherror-text-div" v-else-if="isEmptyAccount" v-html="sanitize(config.errors.emptyaccount)"></div>
    <div class="dashboard-content" v-else-if="isSelectedNotBan">
      <ctnlvl-ban-view v-if="selectedBan.postpaid" :bans="user.ctnLvlBans"/>
      <view-prepaid v-if="selectedBan.prepaid" :prepaids="user.prepaid"/>
    </div>
    <template v-else>
    <template v-if="ban.isActive">
      <div class="dashboard-content">
        <edit-group
        v-if="isGroup"
        :bantoshow="ban"/>
        <create-group
        v-if="!isGroup"
        :bantoshow="ban"
        @ban-selected="banSelected"/>
    </div>
    </template>
    <template v-else-if="ban.isFamcugError">
    <div class="no-account-error">
      <img class="small-red-warning-svg" :src="require('../assets/small-red-warning.svg')" contain height="21px"/>
      <span v-html="sanitize(config.errors.familygroup)"></span>
    </div>
    <div class="dashboard-content">
        <view-group
        :bantoshow="ban"/>
    </div>
    </template>
    <div class="dasherror-text-div" v-else-if="ban.isBusinessCustomer" v-html="sanitize(config.errors.businessban)"></div>
    <div class="dasherror-text-div" v-else-if="ban.isInactiveBan" v-html="sanitize(config.errors.inactiveban)"></div>
    </template>
    <template v-if="!isEmptyAccount && !loading && (ban && !ban.isFamcugError && !ban.isInactiveBan && !ban.isBusinessCustomer || isSelectedNotBan)">
    <div class="question-box">
      <h2>Kérdésed van? Hívd a 1220-at és mi bármiben segítünk!</h2>
    </div>
    <faq/>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import CreateGroup from './dashboard/create-group.vue';
import EditGroup from './dashboard/edit-group.vue';
import ViewGroup from './dashboard/view-group.vue';
import configMixin from '../plugins/configMixin';
import htmlSanitizer from '../plugins/htmlSanitizer';
import viewPrepaid from './dashboard/view-prepaid.vue';
import BanSelect from './BanSelect.vue';
import faq from './dashboard/faq.vue';
import CtnlvlBanView from './dashboard/ctnlvl-ban-view.vue';
import errorMixin from '../plugins/errorMixin';

export default {
  mixins: [configMixin, htmlSanitizer, errorMixin],
  computed: {
    ...mapState('multisim', ['bans', 'selectedBan', 'banList']),
    ...mapState('user', ['user']),
    ...mapState('config', ['limits', 'memberLimits']),
    ...mapGetters('multisim', ['banList']),

    isSelectedNotBan() {
      return (!!this.selectedBan.prepaid || !!this.selectedBan.postpaid);
    },

    isGroup() {
      return this.isBanGroup(this.ban);
    },

    isEmptyAccount() {
      return (this.user && this.user.bans && this.user.prepaid && this.user.ctnLvlBans) && (_.isEmpty(this.user.bans) && _.isEmpty(this.user.prepaid) && _.isEmpty(this.user.ctnLvlBans));
    },
  },
  created() {
    this.loading = !this.isEmptyAccount;
    this.$vuetify.goTo(0);
  },
  methods: {
    isBanGroup(ban) {
      return ban['multisim-group-info'] && ban['multisim-group-info']['group-type'] === '411';
    },

    menuSelected(menu) {
      this.loading = false;
      this.selectedMenu = menu;
      if (menu.type === 'ban') {
        this.banSelected(menu.banid);
      }
    },

    banSelected(banid) {
      if (this.ban.BillingAccountId !== banid) {
        this.calcHiper(this.bans[banid], banid);
      }
      this.ban = this.bans[banid];
      this.addLimitsToStore();
      this.$store.commit('multisim/setSelectedBan', this.ban.BillingAccountId);
    },
    calcHiper(ban, banid) {
      const inGroup = {
        hiperMembers: 0,
        totalMembers: 0,
        prepaidMembers: 0,
        postpaidMembers: 0,
        internetMembers: 0,
        hasNotInGroup: {
          prepaid: false,
          internet: false,
          postpaid: false,
        },
      };
      this.$store.commit('multisim/setHiperMemberSize', { ban: banid, inGroup });
    },
    addLimitsToStore() {
      const memberLimits = {
        postpaidLimit: this.ban['multisim-group-info'] ? this.ban['multisim-group-info']['postpaid-voice-limit'] : 6,
        internetLimit: this.ban['multisim-group-info'] ? this.ban['multisim-group-info']['large-screen-mbi-limit'] : 1,
        prepaidLimit: this.ban['multisim-group-info'] ? this.ban['multisim-group-info']['prepaid-voice-limit'] : 3,
      };
      this.$store.commit('config/setMemberLimits', memberLimits);
    },
  },
  components: {
    viewPrepaid,
    CreateGroup,
    EditGroup,
    BanSelect,
    faq,
    ViewGroup,
    CtnlvlBanView,
  },
  data() {
    return {
      ban: {},
      selectedMenu: {},
      loading: false,
      dialog: false,
      dialog2: false,
      dialog3: false,
      snackbar: false,
      select: '+36 20',
      items: [
        '+36 20',
        '+36 30',
        '+36 50',
        '+36 70',
      ],
    };
  },
};
</script>

<style>
.box-phone-biglink-inner {
  display: block;
  text-align: center;
  color: #002340 !IMPORTANT;
  font-size: 1.2728em;
  font-family: yettel,sans-serif,arial;
}

.dialog-invite-pay-text span {
  display: block;
  font-family: yettel,sans-serif,arial;
  font-size: 1em;
  margin-bottom: 10px;
  color: #002340;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.inner-text-error-dialog-box a {
  color: #18AAF8;
  font-family: yettel,sans-serif,arial;
}

.inner-text-error-dialog-box a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.inner-text-error-dialog-box {
  margin-left: 31px;
}

.dialog-error-listing span img {
  vertical-align: top;
  margin-right: 10px;
  float: left;
}

.dialog-error-listing span {
  display: block;
  font-family: yettel,sans-serif,arial;
  font-size: 1em;
  margin-bottom: 10px;
  color: #002340;
}

.dialog-error-listing {
  max-width: 390px;
  margin: 0 auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.no-account-error {
  max-width: 850px;
  margin: auto;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.no-account-error img {
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
}

.no-account-error span {
  display: block;
  text-align: center;
  font-size: 1em;
  font-family: yettel,sans-serif,arial;
  color: #002340;
}

.no-account-error span a {
  color: #18AAF8;
}

.no-account-error span a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dashboard-content {

}

.dasherror-text-div {
  padding-top: 50px;
  min-height: 50vh;
  max-width: 800px;
  margin: auto;
}

.dasherror-text-div p {
  margin-bottom: 16px;
  font-size: 2.25em;
  font-family: yettel,sans-serif,arial;
  color: #002340;
  max-width: 1200px;
  text-align: center;
  margin: auto;
  line-height: 40px;
  margin-bottom: 50px;
}

.leftside {
  width: 685px;
  margin-left: auto;
  padding: 20px;
  padding-top: 0px;
}

.separator {
  width: 2px;
  background-color: #D4DADD;
  margin-top: 40px;
  border-bottom: 50px solid #ffffff;
  flex: none;
}

.rightside {
  width: 685px;
  margin-right: auto;
  padding: 20px;
  padding-top: 0px;
  padding-right: 10px;
}

.dash-big-title {
  font-family: yettel_bold,sans-serif,arial;
  color: #002340;
  font-size: 3em;
  margin-bottom: 15px;
  margin-left: 10px;
  display: inline-block;
  font-weight: bold;
}

.sub-cont-title {
  color: #002340;
  margin-left: 10px;
  font-size: 1.3em;
  font-family: yettel_bold,sans-serif,arial;
  font-weight: normal;
}

.dash-desc-text {
  margin-left: 10px;
  color: #002340;
}

.subscription-container {
  margin-top: 30px;
}

.sidepanel-desc-container {
  /*min-height: 110px;*/
  padding-left: 10px;
}

.sidepanel-desc-container p {
  font-size: 0.8125em;
  font-family: yettel,sans-serif,arial;
  color: #002340;
}

.sidepanel-desc-container a {
  font-family: yettel,sans-serif,arial;
  font-size: 0.8125em;
  text-decoration: none;
  color: #18AAF8;
}

.sidepanel-desc-container a:hover {
  text-decoration: underline;
}

.dash-create-button {
  float: right;
  width: 217px;
  height: 50px;
  background: #002340;
  border-radius: 24px;
  font-family: yettel_bold,sans-serif,arial;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  outline: none;
  margin-right: 10px;
}

.dash-create-button:hover {
  cursor: pointer;
  background: #01385E;
}

.dash-create-button:disabled {
  float: right;
  width: 217px;
  height: 50px;
  background: #E5E9EC;
  border-radius: 24px;
  font-family: yettel_bold,sans-serif,arial;
  color: #80919F;
  margin-top: 10px;
  outline: none;
}

.discount-div {
  background: #DCFAD9;
  border-radius: 8px;
  display: flex;
  width: calc(100% - 20px);
  min-height: 26px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.discount-div p {
  font-family: yettel,sans-serif,arial;
  font-size: 0.8125em;
  color: #002340;
  margin: auto;
  margin-left: 0px;
}

.discount-smile-svg {
  height: 20px;
  flex: none;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.warning-div {
  border-radius: 6px;
  display: flex;
  width: calc(100% - 20px);
  min-height: 26px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0px;
}

.warning-div p {
  font-family: yettel,sans-serif,arial;
  font-size: 0.8125em;
  color: #002340;
  margin: auto;
  margin-left: 0px;
}

/*boxes*/
.sub-box {
  width: calc(50% - 20px);
  min-height: 150px;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075), 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  display: inline-block;
  vertical-align: top;
}

.sub-people-container {
  display: flex;
  flex-wrap: wrap;
}

.sub-box-header {
  padding: 10px;
  height: 44px;
  display: flex;
  justify-content: space-between;
}

.sub-box-header h3 {
  display: inline-block;
  font-size: 0.9em;
  margin-left: 5px;
  font-family: yettel,sans-serif,arial;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4A575E;
}

.sub-box-header span {
  text-align: center;
  font-size: 0.7em;
  font-family: yettel_bold,sans-serif,arial;
  border-radius: 14px;
  height: 21px;
  line-height: 21px;
  color: #002340;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.information-message {
  max-width: 445px;
  min-height: 46px;
  margin:auto;
  margin-top: 50px;
  font-family: yettel,sans-serif,arial;
  font-size: 18px;
  line-height: 23px;
  text-align: center;

  color: #99A7B3;
}

.sub-box-header .c-default {
  background: #E5E9EC;
}


.box-phone-number {
  text-align: center;
  font-size: 1.3em;
  margin-top: 5px;
  margin-bottom: 15px;
  color: #002340;
  font-family: yettel_light,sans-serif,arial;
}

.box-phone-desc {
  text-align: center;
  font-size: 0.8em;
  line-height: 15px;
  color: #99A7B3;
}

.box-phone-number.inactive {
  color: #99A7B3;
}

.sub-people-container .sub-box .box-phone-biglink {
  display: block;
  text-align: center;
  color: #002340;
  font-size: 0.875em;
  font-family: yettel,sans-serif,arial;
}

.box-phone-biglink:hover, .box-phone-desc-link:hover, .box-phone-biglink-inner:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sub-box .box-phone-desc-link {
  color: #18AAF8;
}

.sub-box-title-pic {
  display: inline-block;
  margin-left: 5px;
  height: 14px !IMPORTANT;
  width: 13px !IMPORTANT;
}

.box-phone-nonumber {
  color: #99A7B3;
  text-align: center;
  font-size: 1.1em;
  font-family: yettel_light,sans-serif,arial;
  line-height: 22px;
  max-width: 400px;
  margin: auto;
  margin-top: 40px;
}

/* subbox inactive */
.sub-box-inactive {
  width: calc(50% - 20px);
  height: 150px;
  border-radius: 8px;
  margin: 10px;
  vertical-align: top;
  border: 1px dashed #99A7B3;
}

.sub-box-empty-message {
  display: flex;
  height: calc(100% - 80px);
  width: 100%;
}

.sub-box-inactive-alt {
  width: calc(50% - 20px);
  height: 150px;
  border-radius: 8px;
  margin: 10px;
  vertical-align: top;
  border: 1px dashed #99A7B3;
  display: inline-block;
}

.sub-people-container .sub-box-inactive-alt .box-phone-biglink {
  display: block;
  text-align: center;
  color: #002340;
  font-size: 0.9em;
  font-family: yettel,sans-serif,arial;
}

.sub-box-title-pic-alt {
  margin: auto;
  height: 62px !IMPORTANT;
  width: 62px !IMPORTANT;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.sub-box-inactive p {
  margin: auto;
  color: #99A7B3;
  font-family: yettel,sans-serif,arial;
  text-align: center;
  padding: 5px;
  font-size: 0.875em;
}

.empty-sub-box-header {
  height: 44px;
  width: 100%;
}

/* end */
.question-box {
  padding: 50px;
}

.question-box h2 {
  text-align: center;
  color: #002340;
  font-size: 1.125em;
  font-family: yettel,sans-serif,arial;
}

/* responsive css here */
@media (max-width: 1170px) {
  .rightside .dash-create-button {
    float: left;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .rightside .dash-big-title {
    display: block;
  }
}

@media (max-width: 1080px) {
  .sub-people-container .sub-box, .sub-people-container .sub-box-inactive-alt {
    width: calc(100% - 20px);
    display: block;
  }

  .sub-people-container .sub-box-inactive {
    width: calc(100% - 20px);
  }

  .subscription-container .sub-people-container {
    flex-wrap: unset;
    display: block;
  }
}

@media (max-width: 860px) {
  .rightside .dash-big-title, .leftside .dash-big-title  {
    font-size: 2.5em;
  }
}

@media (max-width: 655px) {
  .rightside .dash-big-title, .leftside .dash-big-title  {
    font-size: 2em;
  }
}

@media (max-width: 610px) {
  .dasherror-text-div p {
    font-size: 1.8em;
  }
}

@media (max-width: 580px) {
  .dashboard-content .separator {
    display: none;
  }

  .dashboard-content, .group-content-flex {
    display: block;
  }

  .dashboard-content .leftside, .dashboard-content .rightside {
    width: 100%;
    min-width: 275px;
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .rightside .dash-create-button {
    width: calc(100% - 20px);
  }

  .check-list-container span {
    font-size: 0.8em;
  }

  .dialog-title-text {
    font-size: 1.1em;
  }
}

@media (max-width: 330px) {
  .dasherror-text-div p {
    font-size: 1.3em;
  }
}

.invite-card-text {
  font-size: 0.6875em;
  text-align: center;
  color: #99A7B3;
  font-family: yettel,sans-serif,arial;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.invite-card-links {
  display: flex;
}

.invite-card-links a {
  margin: auto;
  display: block;
  color: #18AAF8;
  font-size: 0.875em;
  font-family: yettel,sans-serif,arial;
}

.invite-card-links a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.box-phone-disabled-text {
  color: #99A7B3 !important;
  font-size: 0.6875em !important;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  margin-bottom: 0px !IMPORTANT;
  font-family: yettel,sans-serif,arial;
}
</style>

<template>
  <div>
    <div v-if="!expansion" class="subscription-container">
      <div class="sub-cont-header">
        <h2 class="sub-cont-title">{{label}}</h2>
      </div>
      <div class="information-message" v-if="isEmpty">{{emptyText}}</div>
      <div class="sub-people-container" v-else>
        <component v-for="item in value" :key="item.value.ctn" :is="item.component" :value="item.value" :responseHandler="responseHandler">
          <component v-if="item.cardText" :is="item.cardText.component" :cardText="item.cardText"/>
        </component>
      </div>
    </div>
    <div v-if="expansion" class="subscription-container">
      <v-expansion-panels v-model="prepaidpanel" accordion multiple>
        <v-expansion-panel class="prepaid-expansion-panel">
          <v-expansion-panel-header>
            <h2 class="sub-cont-title">{{label}}</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="information-message" v-if="isEmpty">{{emptyText}}</div>
            <div class="sub-people-container" v-else>
              <component v-for="item in value" :key="item.value.ctn" :is="item.component" :value="item.value" :responseHandler="responseHandler">
                <component v-if="item.cardText" :is="item.cardText.component" :cardText="item.cardText"/>
              </component>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DataCard from './data-card.vue';
import InvitePrepaidCard from './invite-prepaid-card.vue';
import EmptyCard from './empty-card.vue';
import configMixin from '../../plugins/configMixin';
import ActionText from './cardtext/action-text.vue';
import DisabledText from './cardtext/disabled-text.vue';
import InviteText from './cardtext/invite-text.vue';
import DeleteInviteText from './cardtext/delete-invite-text.vue';

export default {
  data: () => ({
    prepaidpanel: [0],
  }),
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  components: {
    DisabledText,
    ActionText,
    DataCard,
    EmptyCard,
    InvitePrepaidCard,
    InviteText,
    DeleteInviteText,
  },
  mixins: [configMixin],
  props: {
    label: String,
    value: Array,
    emptyText: String,
    expansion: Boolean,
    mobileclose: Boolean,
    responseHandler: {
      type: Function,
    },
  },
  computed: {
    ...mapState('multisim', ['inGroupMembers']),
    isEmpty() {
      return !this.value.length;
    },
    isGroup() {
      return this.inGroupMembers.totalMembers >= 2;
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 580 && this.mobileclose) {
        this.prepaidpanel = [1];
      } else {
        this.prepaidpanel = [0];
      }
    },
  },
};
</script>

<style>
.prepaid-expansion-panel {
  background-color: rgba(0,0,0,0) !IMPORTANT;
}

.prepaid-expansion-panel::before {
  box-shadow: none !IMPORTANT;
}

.prepaid-expansion-panel .v-expansion-panel-header {
  padding: 0px;
  height: 48px !IMPORTANT;
  min-height: 48px !IMPORTANT;
  font-size: 1em !IMPORTANT;
}

.prepaid-expansion-panel .v-expansion-panel-header.v-expansion-panel-header--active {
  height: 48px !IMPORTANT;
  min-height: 48px !IMPORTANT;
}

.prepaid-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>

<template>
  <div class="bottom-header-container">
    <div class="bottom-header">
      <div v-for="ban in banList" v-bind:key="ban.BillingAccountId" :class="isActive(ban.BillingAccountId) ? 'ban-container active' : 'ban-container'" @click="selectBan(ban.BillingAccountId, 'ban')">
        <p class="ttitle">Számlázási azonosító:</p>
        <p class="bbold">{{ban.BillingAccountId}}</p>
      </div>
      <div class="ban-container" v-if="isEmptyAccount">
      </div>
      <div :class="selectedBan.postpaid ? 'ban-container active' : 'ban-container'" v-if="user && user.ctnLvlBans && Object.keys(user.ctnLvlBans).length && postpaidLoaded" @click="selectBan(0, 'postpaid')">
        <p class="ttitle">Egyéb számlás</p>
        <p class="bbold">Előfizetésem</p>
      </div>
    </div>
    <v-dialog overlay-color="#002340" overlay-opacity="0.8" :value="confirmDialog" @input="cancel()" max-width="600">
      <a @click="cancel()" class="dialog-x-button">
        <img class="gray-x-svg" :src="require('../assets/gray-x.svg')" contain height="23px"/>
      </a>
      <v-card class="dialog-content">
        <div>
          <span class="dialog-title-text bold">A Prime csoportot még nem hoztad létre!<br>Valóban elhagyod az oldalt?</span>
        </div>
      </v-card>
      <div class="dialog-buttons">
        <v-btn class="dialog-gray-button" text @click="initBan(dialogBan, selectType)">Elhagyom az oldalt</v-btn>
        <v-btn class="dialog-green-button" text @click="cancel()">Létrehozom a Prime csoportot</v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations } from 'vuex';
import MultisimModule from '../modules/MultisimModule';
import multisimMixin from '../plugins/multisimMixin';
import errorMixin from '../plugins/errorMixin';
import datalayerMixin from '../plugins/datalayerMixin';

export default {
  data() {
    return {
      dialogBan: 0,
      confirmDialog: false,
      prepaidLoaded: false,
      postpaidLoaded: false,
      selectType: '',
    };
  },
  props: {
    isEmptyAccount: Boolean,
  },
  mixins: [multisimMixin, errorMixin, datalayerMixin],
  created() {
    if (this.user && Object.keys(this.user).length) {
      this.addBansToStore(this.user.bans);
    }
    if (this.user && this.user.prepaid && Object.keys(this.user.prepaid).length) {
      this.addPrepaidsToStore(this.user.prepaid);
    }
    if (this.user && this.user.ctnLvlBans && Object.keys(this.user.ctnLvlBans).length) {
      this.addPostpaidsToStore(this.user.ctnLvlBans);
    }
  },
  computed: {
    ...mapState('multisim', ['selectedBan', 'inGroupMembers', 'bans']),
    ...mapState('user', ['user']),
    ...mapGetters('multisim', ['banList']),
    isBanEdited() {
      return (this.bans[this.selectedBan] && !this.isBanGroup(this.bans[this.selectedBan]) && this.inGroupMembers.totalMembers !== 0);
    },
  },
  watch: {
    postpaidLoaded() {
      if (this.user && this.user.bans && this.user.ctnLvlBans && (_.isEmpty(this.user.bans) && !_.isEmpty(this.user.ctnLvlBans))) {
        this.selectPostpaid();
      }
    },
    prepaidLoaded() {
      if (this.user && this.user.bans && this.user.prepaid && (_.isEmpty(this.user.bans) && !_.isEmpty(this.user.prepaid))) {
        this.selectPrepaid();
      }
    },
  },
  methods: {
    ...mapMutations('error', ['setError']),
    isBanGroup(ban) {
      return ban['multisim-group-info'] && ban['multisim-group-info']['group-type'] === '411';
    },
    cancel() {
      this.confirmDialog = false;
    },
    addBansToStore(banList) {
      let callFinished = 0;
      const NO_CALL_FINISHED = 0;
      Object.values(banList).forEach((ban) => {
        MultisimModule.getMultisimInfo(ban.BillingAccountId).then((resp) => {
          const storeBan = this.mapMultiSIM(resp, ban);
          this.$store.commit('multisim/addBan', storeBan);
          if (storeBan.ban.isActive && callFinished++ === NO_CALL_FINISHED) {
            this.initBan(ban.BillingAccountId, 'ban');
          }
        }).catch((err) => {
          // console.log(err);
          this.handleError(err);
        });
      });
    },

    addPrepaidsToStore(prepaidList) {
      const call = Object.keys(prepaidList).length;
      let callFinished = 0;
      if (call) {
        Object.keys(prepaidList).forEach((ctn) => {
          MultisimModule.getCtnInfo(ctn).then((resp) => {
            const [memberInfo] = resp['multisim-member-list'];
            const groupInfo = resp['multisim-group-info'];

            const storeCTN = {
              'price-plan-multisim-options': memberInfo['price-plan-multisim-options'],
              ...this.user.prepaid[memberInfo.ctn],
              ...memberInfo,
            };

            if (groupInfo) {
              storeCTN.groupInfo = {
                banid: groupInfo['ban-id'],
                'group-id': groupInfo['group-id'],
                type: groupInfo['group-type'],
                'group-status': memberInfo['group-status'],
              };
            }
            this.$store.commit('user/extendPrepaid', { ...this.mapCTN(storeCTN), 'group-status': 'N' });
          }).catch((err) => {
            this.$store.commit('user/extendPrepaid', ctn);
            this.handleError(err);
          }).finally(() => {
            callFinished++;
            if (call === callFinished) {
              this.prepaidLoaded = true;
            }
          });
        });
      }
    },
    addPostpaidsToStore(banList) {
      const call = Object.values(banList).reduce((out, inp) => out + inp.ctns.length, 0);
      let callFinished = 0;
      if (call) {
        Object.values(banList).forEach((ban) => {
          ban.ctns.forEach((ctn, index) => {
            MultisimModule.getCtnInfo(ctn.SubscriberNumber).then((resp) => {
              this.$store.commit('user/extendCtnLvlPostpaids', { 'ban-id': ctn.BillingAccountId, index, resp: { ...resp, 'multisim-member-list': [this.mapCTN(resp['multisim-member-list'][0])] } });
            }).catch((err) => {
              // console.log(err);
              this.handleError(err);
            }).finally(() => {
              callFinished++;
              if (call === callFinished) {
                this.postpaidLoaded = true;
              }
            });
          });
        });
      }
    },

    selectPrepaid() {
      this.pushPrepaidCtns();
      this.$store.commit('multisim/setSelectedBan', 'prepaid');
      this.$emit('menu-selected', { type: 'prepaid' });
    },
    selectPostpaid() {
      this.pushCtnLevelBans();
      this.$store.commit('multisim/setSelectedBan', 'postpaid');
      this.$emit('menu-selected', { type: 'ctn-lvl-postpaid' });
    },
    selectBan(ban, type) {
      this.selectType = type;
      if (this.isBanEdited && this.selectedBan !== ban) {
        this.confirmDialog = true;
        this.dialogBan = ban;
      } else {
        this.initBan(ban, type);
      }
    },
    initBan(ban, type) {
      this.cancel();
      if (type === 'prepaid') {
        this.selectPrepaid();
      }
      if (type === 'postpaid') {
        this.selectPostpaid();
      }
      if (type === 'ban') {
        this.$store.commit('multisim/setSelectedBan', ban);
        this.$emit('menu-selected', { banid: ban, type: 'ban' });
      }
    },
    isActive(ban) {
      return this.selectedBan === ban;
    },
  },

};
</script>

<style>
.dialog-green-button {
  max-width: 300px !important;
}

.ban-container {
  color: #80919F;
  font-size: 1em;
  padding-top: 12px;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  height: 64px;
}

.ban-container .ttitle {
  font-size: 0.8em;
  font-family: yettel_bold,sans-serif,arial;
}

.ban-container.active {
  color: #002340;
  border-bottom: 3px solid #002340;
}

.bottom-header .ban-container p {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.ban-container .bbold {
  font-family: yettel_bold,sans-serif,arial;
  font-size: 1.1em;
}

.bottom-header-container {
  width: 100%;
  background: #C4DFE9;
}

.bottom-header .ban-container:nth-child(1) {
  margin-left: 0px;
}
</style>

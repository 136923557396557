import { mapState } from 'vuex';
import _ from 'lodash';
import ActionText from '../models/ActionText';
import DisabledCardText from '../models/DisabledCardText';
import InviteCardText from '../models/InviteCardText';
import DeleteInviteText from '../models/DeleteInviteText';
import chainMixin from './chainMixin';
import messages from '../config/cardTextMessages.json';
import datalayerMixin from './datalayerMixin';
import multisimMixin from './multisimMixin';
import errorMixin from './errorMixin';

export default {
  data() {
    return {
      messages,
    };
  },
  computed: {
    phoneNumber() {
      return this.formatCtn(this.selectedCTN.ctn);
    },
    ...mapState('user', ['user']),
    ...mapState('multisim', ['bans']),
    ...mapState('config', ['config']),

  },
  mixins: [
    chainMixin,
    datalayerMixin,
    multisimMixin,
    errorMixin,
  ],
  methods: {
    mapError(err) {
      return this.strictSanitize(this.config.errorcodes[`${err}`] || this.config.errorcodes.default);
    },
    handleRemovePrepaid(ctn) {
      const memberInfo = this.user.prepaid[ctn.ctn];
      this.$store.commit('user/extendPrepaid', {
        ...memberInfo,
        groupInfo: {},
        isGray: '',
        isMember: false,
        'group-status': 'N',
        colorClass: '',
        'nominator-ctn': null,
      });
    },
    handleAddPrepaid(ctn, groupInfo) {
      const memberInfo = this.user.prepaid[ctn.ctn];

      const storeCTN = {
        'price-plan-multisim-options': memberInfo['price-plan-multisim-options'],
        'nominator-ctn': ctn['nominator-ctn'],
        ...this.user.prepaid[memberInfo.ctn],
        ...memberInfo,
      };

      if (groupInfo) {
        storeCTN.groupInfo = {
          banid: groupInfo['ban-id'],
          'group-id': groupInfo['group-id'],
          type: groupInfo['group-type'],
          'group-status': ctn['group-status'],
        };
      }
      this.$store.commit('user/extendPrepaid', { ...this.mapCTN(storeCTN), 'group-status': 'N' });
    },
    storeNewBan(resp) {
      const banid = resp['multisim-group-info']['ban-id'];
      const storeBan = this.mapMultiSIM(resp, this.user.bans[resp['multisim-group-info']['ban-id'] || this.ban.BillingAccountId]);
      const removedPrepaids = _.differenceWith(this.bans[banid]['multisim-member-list'], storeBan.resp['multisim-member-list'], (a, b) => !(a.ctn - b.ctn || a['group-status'] !== b['group-status']));
      const addedPrepaids = _.differenceWith(storeBan.resp['multisim-member-list'], this.bans[banid]['multisim-member-list'], (a, b) => !(a.ctn - b.ctn || a['group-status'] !== b['group-status']));
      this.$store.commit('multisim/updateBan', storeBan);
      const groupInfo = storeBan.resp['multisim-group-info'];
      const ownedPrepaidFilter = ctn => ctn.isPrepaid && _.some(Object.keys(this.user.prepaid), k => k === ctn.ctn);
      removedPrepaids
        .filter(ownedPrepaidFilter)
        .forEach(this.handleRemovePrepaid);
      addedPrepaids
        .filter(ownedPrepaidFilter)
        .forEach((ctn) => {
          this.handleAddPrepaid(ctn, groupInfo);
        });
      return storeBan;
    },
    formatCtn(ctn) {
      const a = ctn;
      if (a) {
        return `+36 ${a.slice(0, 2)} ${a.slice(2, 5)} ${a.slice(5, 9)}`;
      }
      return '';
    },
    isTariffAddable(ctn) {
      return ctn.isTariffAddable ? '' : this.messages.negativeBranches.wrongTariff[ctn.ref];
    },
    isCommitEndDateValid(ctn) {
      return ctn.isCommitEndDateValid ? '' : this.messages.negativeBranches.commitEnded;
    },
    isActive(ctn) {
      return ctn.isActive ? '' : this.messages.negativeBranches.inactive;
    },
    getErrorsLink(errorChain, ctn) {
      const errorMessages = errorChain.map(check => check(ctn)).filter(k => k);
      if (errorMessages.length) {
        return () => new DisabledCardText(ctn, errorMessages);
      }
      return () => null;
    },
    createLimitLink(members, limit) {
      if (members >= limit) {
        return ctn => this.createDisableText(ctn, this.messages.overLimit[ctn.ref]);
      }
      return () => null;
    },
    suspendedPrepaidLink(ctn) {
      if (ctn.isSuspended) {
        return this.createDisableText(ctn, 'A feltöltőkártya csoporttagsága felfüggesztett állapotú, mert nincs elég egyenleg az időszaki díj levonásához. <a class="box-phone-biglink-inner gtmMultiSimPrepaidTopupCta" href="https://www.yettel.hu/feltoltes">Tölts fel itt és most!</a>');
      }
      return null;
    },
    createDisableText(ctn, message) {
      return new DisabledCardText(ctn, [message]);
    },
    invitedPrepaid(ctn, callback) {
      return () => {
        if (ctn['group-status'] === 'I' || (ctn.groupInfo && ctn.groupInfo['group-status'] === 'I')) {
          if (_.has(this.user.prepaid, ctn.ctn)) {
            return new InviteCardText(ctn, callback);
          }
          return new DeleteInviteText(ctn, callback);
        }
        return null;
      };
    },
    produceAddAction(value, callback) {
      return () => new ActionText(value, callback, 'Hozzáadás a csoporthoz');
    },
    produceRemoveAction(value, callback) {
      return () => {
        if (this.config.kidTariff.includes(value['price-plan'])) {
          return new DisabledCardText(value, [this.config.text.kidDeleteWarning]);
        }
        return new ActionText(value, callback, 'Eltávolítás a csoportból');
      };
    },
  },
};

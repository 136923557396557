<template>
  <div>
<v-row justify="center">
        <v-dialog overlay-color="#002340" overlay-opacity="0.8" :value="enable" @input="onCancel()" v-if="!failed && !success" max-width="600" :persistent="loading">
          <a @click="onCancel()" class="dialog-x-button">
            <img  class="gray-x-svg" :src="require('../../../assets/gray-x.svg')" contain height="23px"/>
          </a>
          <v-form v-model="valid" @submit.prevent="submit(selectedCtn)" ref="form">
            <v-card class="dialog-content">
              <div>
                <span class="dialog-title-text">Biztos, hogy hozzá szeretnéd adni a <b>{{phoneNumber}}</b> telefonszámot a csoporthoz?</span>
                <p class="prepaid-dialog-text upper text">Amennyiben a feltöltőkártyás előfizetésedet hozzáadod a Prime csoporthoz, időszaki díjat vonunk le a hozzáadott előfizetés egyenlegéből.</p>
                <p class="prepaid-dialog-text upper middle">A szolgáltatás díja: 1390 Ft / 30 nap.</p>
                <p class="prepaid-dialog-text2 bottom text">Kérjük, add meg, hogy melyik, már Prime csoportban lévő előfizetéseddel szeretnéd hozzáadni a feltöltőkártyás előfizetésedet a Prime csoporthoz. </p>
              </div>
            </v-card>
            <div>
              <div class="dialog-gray-input-full-container" v-if="!(failed || success)">
                  <div class="dialog-gray-input-container">
                    <img class="input-phone-svg" :src="require('../../../assets/phone.svg')" contain height="15px"/>
                    <v-select
                      v-model="selectedCtn"
                      :items="nominators"
                      class="dialog-gray-combobox-full"
                      required
                    ></v-select>
                  </div>
                </div>
            </div>
            <div class="dialog-buttons">
              <v-btn v-if="!(failed || success)" :disabled="loading" class="dialog-gray-button" text @click="onCancel()">Mégsem</v-btn>
              <v-btn type="submit" v-if="!(failed || success)" :disabled="loading" class="dialog-green-button" text>
                Hozzáadás
                <img class="loading-animation dialog" v-if="loading" :src="require('../../../assets/loader-gray.gif')" contain height="15px"/>
              </v-btn>
            </div>
          </v-form>
        </v-dialog>
      </v-row>

     <error-dialog :failed="failed" :onCancel="onCancel" title="A csoporthoz adás sikertelen" :failedMessage="errorDetail"/>
     <success-dialog :success="success" :onCancel="onCancel" title="Az előfizetést hozzáadtad a Prime csoporthoz"/>
  </div>
</template>

<script>
import errorDialog from './error-dialog.vue';
import successDialog from './success-dialog.vue';

export default {
  components: {
    errorDialog,
    successDialog,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    phoneNumber: String,
    submit: {
      type: Function,
    },
    cancel: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
    failed: Boolean,
    success: Boolean,
    failedMessage: String,
    nominators: Array,
    errorDetail: String,
  },
  data() {
    return {
      selectedCtn: this.nominators[0],
      valid: null,
    };
  },
  watch: {
    nominators(newList) {
      this.selectedCtn = newList[0];
    },
  },
  methods: {
    onCancel() {
      if (!this.loading) {
        this.cancel();
      }
    },
  },
};
</script>

<style>

</style>

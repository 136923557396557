<template>
<div>
  <span v-if="cardText.value.nominatorFormatted && cardText.value.isMember" class="invite-card-text">Meghívta a Prime csoportba: {{cardText.value.nominatorFormatted}}</span>
  <a class="box-phone-biglink" @click="cardText.callback(cardText.value)">{{cardText.message}}</a>
</div>
</template>
<script>
export default {
  props: {
    cardText: Object,
  },
};
</script>

<template>
        <v-dialog overlay-color="#002340" overlay-opacity="0.8" :value="success" @input="onCancel()" max-width="600">
          <a @click="onCancel()" class="dialog-x-button">
          <img  class="gray-x-svg" :src="require('../../../assets/gray-x.svg')" contain height="23px"/>
          </a>
          <v-card class="dialog-content">
            <div v-if="success">
              <span class="dialog-title-text bold">{{title}}</span>
              <img class="red-error-svg" :src="require('../../../assets/green-ok.svg')" contain height="58px"/>
              <span v-if="text" class="dialog-title-text">{{text}}</span>
            </div>
          </v-card>
          <div class="dialog-buttons">
            <v-btn v-if="success" class="dialog-green-button" text @click="onCancel()">Rendben</v-btn>
          </div>
        </v-dialog>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    title: String,
    text: String,
    onCancel: Function,
  },
};
</script>

<style>

</style>

<template>
<div>
  <div class="box-phone-disabled-text"> Ez a szám visszaigazolásra vár. Meggondoltad magad? </div>
  <a class="box-phone-biglink" @click="cardText.callback(cardText.value, false, true)">Meghívás visszavonása</a>
</div>
</template>
<script>
export default {
  props: {
    cardText: Object,
  },
};
</script>

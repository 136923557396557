<template>
<div>
  <p v-if="cardText.messages.length<2" class="box-phone-disabled-text" v-html="cardText.messages[0]"></p>
<div v-else>
    <p class="box-phone-disabled-text">A tarifacsomag nem adható hozzá a Prime csoporthoz.</p>
    <a class="box-phone-biglink" @click="moreClicked">További részletek</a>
<v-dialog overlay-color="#002340" overlay-opacity="0.8" v-model="dialog" max-width="600">
      <a @click="cancel()" class="dialog-x-button">
        <img class="gray-x-svg" :src="require('../../../assets/gray-x.svg')" contain height="23px"/>
      </a>
      <v-card class="dialog-content">
        <div>
          <span class="dialog-title-text">Az előfizetést nem lehet a Prime csoporthoz hozzáadni</span>
          <div class="dialog-error-listing">
            <span v-for="(message, index) in cardText.messages" :key="index"><img class="small-red-warning-svg" :src="require('../../../assets/small-red-warning.svg')" contain height="21px"/>{{message}}</span>
          </div>
        </div>
      </v-card>
      <div class="dialog-buttons">
        <v-btn class="dialog-green-button" @click="cancel" text>Rendben</v-btn>
      </div>
    </v-dialog>
</div>
</div>
</template>
<script>
export default {
  props: {
    cardText: Object,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    moreClicked() {
      this.dialog = true;
      // console.log('clicked');
    },
  },
};
</script>

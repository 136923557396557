<template>
  <group-view :inGroup="filledGroups.inGroup"
              :notInGroup="filledGroups.notInGroup"
              :responseHandler="ctnClicked"
              :hidePrepaid="true"
              :isCreate="true">
    <button class="dash-create-button" :disabled="!isCreateable || loading" @click="save">
      <span>Létrehozás</span>
      <img class="loading-animation" v-if="loading" :src="require('../../assets/loader-gray.gif')" contain height="15px"/>
    </button>
    <template v-slot:rightSideText>
      <p>{{strictSanitize(config.text.memberadvice)}}</p>
    </template>
    <template v-slot:righticon>
      <img v-if="!isCreateable" class="warning-svg" :src="require('../../assets/warning.svg')" contain height="15px">
      <img v-if="isCreateable" class="okay-svg" :src="require('../../assets/gray-okay.svg')" contain height="15px">
    </template>
    <v-dialog overlay-color="#002340" overlay-opacity="0.8" :persistent="loading" :content-class="loading ? 'loading-dialog':''" :value="creation.dialog" @input="cancel()" max-width="600">
          <div v-if="loading" class="loader-container">
            <div class="loader">Loading...</div>
          </div>
          <div v-else>
            <a @click="cancel()" class="dialog-x-button warning-creategroup">
              <img class="gray-x-svg" :src="require('../../assets/gray-x.svg')" contain height="23px"/>
            </a>
          <v-card v-if="creation.groupFailed" class="dialog-content">
              <span class="dialog-title-text bold">Hiba történt!<br>A Prime csoport létrehozása nem sikerült. Kérjük, próbáld újra!</span>
              <img class="red-error-svg" :src="require('../../assets/red-error.svg')" contain height="58px"/>
          </v-card>
          <v-card v-else-if="creation.failedCtns.length !== 0" class="dialog-content">
              <span class="dialog-title-text bold">Létrehoztad a Prime csoportodat</span>
              <img class="red-error-svg" :src="require('../../assets/yellow-mark.svg')" contain height="58px"/>
              <span class="dialog-title-text">A csoport létrehozása sikeres volt, de nem sikerült az összes kiválasztott előfizetést a csoporthoz adni.</span>
              <span class="dialog-title-text warningdialog">A hiba az alábbi telefonszámú előfizetéseket érinti:</span>
              <span class="dialog-span-list" v-for="ctn in creation.failedCtns" :key="ctn">
                +36 {{ctn}}
              </span>
          </v-card>
          <v-card v-else class="dialog-content">
              <span class="dialog-title-text bold">Létrehoztad a Prime csoportodat</span>
              <img class="red-error-svg" :src="require('../../assets/green-ok.svg')" contain height="58px"/>
              <span class="dialog-title-text">A csoport létrehozás sikeres volt, adj hozzá további előfizetéseket még több kedvezményért.</span>
          </v-card>
          <div class="dialog-buttons">
            <v-btn class="dialog-green-button" text @click="cancel()">Rendben</v-btn>
          </div>
          </div>
        </v-dialog>
  </group-view>
</template>
<script>
import _ from 'lodash';
import GroupView from './group-view.vue';
import groupMixin from '../../plugins/groupMixin';
import MultsimModule from '../../modules/MultisimModule';

export default {
  data() {
    return {
      loading: false,
      creation: {
        dialog: false,
        groupFailed: false,
        failedCtns: [],
        failedReasons: [],
      },
    };
  },
  mixins: [groupMixin],
  props: {
    value: Object,
  },
  components: { GroupView },
  computed: {
    isCreateable() {
      return this.getHiperMembers(this.groups.inGroup.postPaid).length >= 2;
    },
  },
  methods: {
    textMemberPrep(ctn) {
      const methodChain = [
        this.produceRemoveAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textMemberPost(ctn) {
      const methodChain = [
        this.produceRemoveAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textMemberInternet(ctn) {
      const methodChain = [
        this.produceRemoveAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textNotMemberPrep() {
      return null;
    },
    textNotMemberPost(ctn) {
      const errorChain = [
        this.isTariffAddable,
        this.isCommitEndDateValid,
        this.isActive,
      ];
      const methodChain = [
        this.createLimitLink(this.inGroupMembers.postpaidMembers, this.postpaidLimit),
        this.getErrorsLink(errorChain, ctn),
        this.produceAddAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textNotMemberInternet(ctn) {
      const errorChain = [
        this.isCommitEndDateValid,
        this.isTariffAddable,
        this.isActive,
      ];
      const methodChain = [
        this.createLimitLink(this.inGroupMembers.internetMembers, this.internetLimit),
        this.getErrorsLink(errorChain, ctn),
        this.produceAddAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    cancel() {
      this.creation.dialog = false;
      this.creation.groupFailed = false;
      this.$emit('ban-selected', this.ban.BillingAccountId);
    },
    save() {
      const { inGroup } = this.separatedGroups;
      const hipers = inGroup.filter(ctn => ctn.isHiper);
      const [firstHiper, secondHiper] = hipers;
      const remaingCtns = inGroup.filter((item => ![firstHiper, secondHiper].some(used => used.ctn === item.ctn)));
      this.loading = true;
      this.creation.dialog = true;
      MultsimModule.createGroup(this.ban.BillingAccountId, firstHiper.ctn, secondHiper.ctn)
        .then(response => this.handleCreationResponse(response, remaingCtns, firstHiper.ctn, secondHiper.ctn))
        .catch(e => this.handleCreationError(e, firstHiper.ctn, secondHiper.ctn));
    },

    handleCreationResponse(response, remaingCtns, firstHiper, secondHiper) {
      const groupId = response['multisim-group-info']['group-id'];
      const groupPromise = new Promise((resolve) => { resolve(response); });
      const addPromises = [groupPromise, ...remaingCtns.map(ctn => MultsimModule.addPostpaid(this.ban.BillingAccountId, ctn.ctn))];
      Promise.allSettled(addPromises)
        .then((r) => {
          this.handleAddResponses(r);
          this.pushCreateAdds(r, remaingCtns, groupId);
        })
        .finally(() => {
          this.loading = false;
          if (this.creation.failedCtns.length !== 0) {
            this.pushCreateGroup(null, this.creation.failedReasons, this.getEricaData(firstHiper, secondHiper, false, groupId));
            MultsimModule.getMultisimInfo(this.ban.BillingAccountId).then((resp) => {
              this.responseHandler(resp);
            }).catch(err => this.handleError(err));
          } else {
            this.pushCreateGroup(null, null, this.getEricaData(firstHiper, secondHiper, false, groupId));
          }
        });
    },

    handleAddResponses(responses) {
      responses
        .filter(result => result.status !== 'fulfilled')
        .forEach((result) => { this.creation.failedReasons.push(result.reason.response.data.message); this.creation.failedCtns.push(JSON.parse(result.reason.config.data).ctn); });
      const lastSucceeded = responses.filter(result => result.status === 'fulfilled').pop();
      this.responseHandler(lastSucceeded.value);
    },

    pushCreateAdds(responses, remaingCtns, groupId) {
      responses.forEach((r) => {
        const idx = responses.indexOf(r) - 1;
        const success = r.status === 'fulfilled';
        if (idx >= 0) {
          this.pushAddRemoveCtn(remaingCtns[idx], true, success ? null : r.reason, this.getEricaData(remaingCtns[idx].ctn, null, false, groupId));
        }
      });
    },
    handleCreationError(error, firstHiper, secondHiper) {
      this.pushCreateGroup(error, null, this.getEricaData(firstHiper, secondHiper, false, null));
      this.creation.groupFailed = true;
      // console.log(error);
      this.loading = false;
    },
    ctnClicked(ctn) {
      if (_.some(this.separatedGroups.notInGroup, k => ctn.ctn === k.ctn)) {
        this.separatedGroups.notInGroup = this.separatedGroups.notInGroup.filter(k => k.ctn !== ctn.ctn);
        this.separatedGroups.inGroup.push(ctn);
        ctn['group-status'] = 'M';
      } else {
        this.separatedGroups.inGroup = this.separatedGroups.inGroup.filter(k => k.ctn !== ctn.ctn);
        this.separatedGroups.notInGroup.push(ctn);
        ctn['group-status'] = 'N';
      }
      this.calcHiper(this.ban, this.ban.BillingAccountId);
    },
  },
};
</script>

<style>
.dash-create-button span {
  vertical-align: top;
}

.dash-create-button img {
  margin-left: 10px;
  margin-top: 3px;
}

.v-dialog.loading-dialog {
  background: none;
  box-shadow: none !important;
  height: 120px;
}

.v-dialog.loading-dialog .loader-container {
  display: flex;
}

.dialog-span-list {
  text-align: center;
  font-family: telenor;
  font-size: 1.3125em;
  display: block;
}

.dialog-x-button.warning-creategroup {
  margin-top: 0px;
}

.dialog-title-text.warningdialog {
  margin-top: 15px;
}
</style>

<template>
    <group-view :inGroup="filledGroups.inGroup"
                :notInGroup="filledGroups.notInGroup"
                :showDesc="false"
                :showMembers="false"/>
</template>
<script>
import GroupView from './group-view.vue';
import groupMixin from '../../plugins/groupMixin';

export default {
  data() {
    return {
      loading: false,
      creation: {
        dialog: false,
        groupFailed: false,
        failedCtns: [],
      },
    };
  },
  mixins: [groupMixin],
  props: {
    value: Object,
  },
  components: { GroupView },
  computed: {
  },
  methods: {
    getTextCard(ctn) {
      if (ctn['product-code'] === 'PREP') { return this.createDisableText(ctn, 'A feltöltőkártyás előfizetések csak a Prime csoport létrehozása után adhatók hozzá a csoporthoz.'); }
      return null;
    },
  },
};
</script>

<style>
.v-dialog.loading-dialog {
  background: none;
  box-shadow: none;
  height: 120px;
}

.v-dialog.loading-dialog .loader-container {
  display: flex;
}

.dialog-span-list {
  text-align: center;
  font-family: telenor;
  font-size: 1.3125em;
  display: block;
}

.dialog-x-button.warning-creategroup {
  margin-top: 0px;
}

.dialog-title-text.warningdialog {
  margin-top: 15px;
}


</style>

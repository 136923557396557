export default class CardText {
  constructor(priority) {
    this.priority = priority;
  }

  get priority() {
    return Number.isInteger(this.prioHolder) ? this.prioHolder : Number.MAX_SAFE_INTEGER;
  }

  set priority(priority) {
    this.prioHolder = priority;
  }
}

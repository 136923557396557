<template>
  <a class="sub-box-inactive-alt" @click="value.Click">
    <img class="sub-box-title-pic-alt" :src="require('../../assets/plus.svg')" contain height="62px"/>
    <a class="box-phone-biglink">{{value.linkTitle}}</a>
  </a>
</template>

<script>
export default {
  props: {
    value: Object,
  },
};
</script>
<style scoped>
.white {
  background-color: #ffffff;
}
</style>

import CardText from './CardText';

export default class ActionText extends CardText {
  constructor(value, callback, message) {
    super(0);
    this.value = value;
    this.callback = callback;
    this.message = message;
    this.component = 'action-text';
  }
}

<template>
   <div class="group-content-flex">
    <div id="elofizeteseim" class="leftside">
    <h1 class="dash-big-title">{{notInGroupLabel}}</h1>
    <slot name="leftTableInfo"/>
    <data-table v-if="notInGroup.length" :panels="notInGroup" :responseHandler="responseHandler"/>
    <slot v-else name="emptyNotInGroup"/>
  </div>
  <div class="separator"></div>
  <div id="hipercsoport" class="rightside">
    <h1 class="dash-big-title">{{inGroupLabel}}</h1>
    <slot/>
    <slot name="rightTableInfo"/>
    <p v-if="!showMembers" class="information-message nosub">Nincs előfizetés a Prime csoportban</p>
    <data-table v-else-if="inGroup.length" :panels="inGroup" :responseHandler="responseHandler"/>
    <slot v-else name="emptyInGroup"/>
    </div>
  </div>
</template>

<script>
import configMixin from '../../plugins/configMixin';
import htmlSanitizer from '../../plugins/htmlSanitizer';
import DataTable from './data-table.vue';

export default {
  mixins: [configMixin, htmlSanitizer],
  components: {
    DataTable,
  },
  props: {
    warningMessage: String,
    notInGroupLabel: String,
    inGroupLabel: String,
    notInGroup: Array,
    inGroup: Array,
    showDesc: {
      type: Boolean,
      default: true,
    },
    showMembers: {
      type: Boolean,
      default: true,
    },
    responseHandler: {
      type: Function,
    },
    isCreate: Boolean,
  },
};
</script>

<style>
.group-content-flex {
  display: flex;
}

.okay-svg {
  flex: none;
  margin: auto;
  margin-left: 10px;
  margin-right: 0;
  background: #CDFABD;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  padding: 4px;
}

.warning-svg {
  flex: none;
  margin: auto;
  margin-left: 10px;
  margin-right: 0;
  background: #FCCFD1;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  padding: 4px;
}

.information-message.nosub {
  margin-top: 150px;
}
</style>

import { mapState } from 'vuex';
import moment from 'moment';

export default {
  methods: {
    isFamcugError(ban) {
      const result = ban && ban['multisim-group-info'] && ban['multisim-group-info']['group-type'] && ban['multisim-group-info']['group-type'] !== '411';
      return !!result;
    },
    isBusinessCustomer(ban) {
      const result = ban && ban.isBusinessCustomer;
      return !!result;
    },
    isInactiveBan(ban) {
      const result = ban && ban.Status !== 'O';
      return !!result;
    },

    mapMultiSIM(resp, ban) {
      const ctns = [];
      resp['multisim-member-list'].forEach((c) => {
        const mappedCTN = this.mapCTN({
          ...c,
          name: ban.ctns.find(ctn => ctn.SubscriberNumber === c.ctn) ? ban.ctns.find(ctn => ctn.SubscriberNumber === c.ctn).NickName : '',
        });
        ctns.push(mappedCTN);
      });
      const isActive = !this.isBusinessCustomer(ban) && !this.isFamcugError(resp) && !this.isInactiveBan(ban);
      const status = {
        isInactiveBan: this.isInactiveBan(ban),
        isBusinessCustomer: this.isBusinessCustomer(ban),
        isFamcugError: this.isFamcugError(resp),
        isActive,
      };
      const storeBan = {
        banid: ban.BillingAccountId,
        ban: {
          ...status,
          ...ban,
        },
        resp: {
          ...ban,
          ...status,
          ...resp,
          'multisim-member-list': ctns,
        },
      };
      return storeBan;
    },
    mapCTN(ctn) {
      const newCTN = {
        ...ctn,

        get isActive() {
          return this['ctn-status'] === 'Active' || this['ctn-status'] === 'A';
        },

        get isSuspended() {
          return this['group-status'] === 'S';
        },

        get isSuspendedCtn() {
          return this['ctn-status'] === 'Suspend';
        },

        isCommitEndDateValid: !!ctn['commit-end-date'] && moment(ctn['commit-end-date'], 'YYYY-MM-DD').isAfter(moment()),

        nominatorFormatted: ctn['nominator-ctn'] ? `+36 ${ctn['nominator-ctn'].slice(0, 2)} ${ctn['nominator-ctn'].slice(2, 5)} ${ctn['nominator-ctn'].slice(5, 9)}` : null,

        get isPrepaid() {
          return this['product-code'] === 'PREP';
        },

        get isPostpaid() {
          return !this.isPrepaid;
        },

        get isMember() {
          if (this['group-status'] === 'M' || this['group-status'] === 'S' || this['group-status'] === 'I') return true;
          return false;
        },

        get isHiper() {
          return this['price-plan-multisim-options'] ? this['price-plan-multisim-options']['create-group'] : false;
        },

        get isActionActive() {
          return (this.isActive && this.isCommitEndDateValid && this.isTariffAddable && this.isPostpaid && this.isActive)
          || (!this.isSuspended && this.isPrepaid && this.isTariffAddable && this.isActive)
          || this['group-status'] === 'M';
        },

        get isTariffAddable() {
          if (this.isPrepaid) {
            return this['price-plan-multisim-options'] ? this['price-plan-multisim-options']['add-as-prepaid-member'] : false;
          }
          return this['price-plan-multisim-options']['add-as-postpaid-member'];
        },

        get colorClass() {
          if ((!this.isTariffAddable && this['group-status'] !== 'M')
          || this['group-status'] === 'S'
          || !(this['ctn-status'] === 'A' || this['ctn-status'] === 'Active')) return ' red';
          if (this['group-status'] !== 'M' && !this.isCommitEndDateValid && this.isPostpaid) {
            return ' red';
          }
          if (this.isMember && this['group-status'] !== 'I') {
            if (this['product-code'] === 'PREP') return ' blue';
            if (this['product-code'] === 'REGL') return ' green';
            if (this['product-code'] === 'MBIN') return ' yellow';
          }
          return '';
        },

        get isGray() {
          return (this.isActionActive && this['group-status'] !== 'I') ? '' : ' gray';
        },

        get getType() {
          if (this['product-code'] === 'PREP') return 'feltöltőkártyás';
          if (this['product-code'] === 'REGL') return 'Számlás';
          if (this['product-code'] === 'MBIN') return 'Otthoni internet';
          return '';
        },

        get ref() {
          if (this['product-code'] === 'PREP') return 'prepaid';
          if (this['product-code'] === 'REGL') return 'postpaid';
          if (this['product-code'] === 'MBIN') return 'internet';
          return '';
        },
      };
      return newCTN;
    },

  },
  computed: {
    ...mapState('config', ['limits', 'memberLimits']),
  },
};

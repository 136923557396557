<template>
  <div class="dashboard-content">
  <div class="no-account-error">
      <img class="small-red-warning-svg" :src="require('../../assets/small-red-warning.svg')" contain height="21px"/>
      <span>A Yettel fiókhoz telefonszámmal rendelted hozzá az előfizetéseidet!</span>
      <span v-html="sanitize(config.text.CtnLevelBanView)"></span>
    </div>
  <data-view inGroupLabel="Prime csoport"
             notInGroupLabel="Előfizetéseim"
             :inGroup="groups.inGroup"
             :notInGroup="groups.outGroup"
              >
  </data-view>
  </div>
</template>

<script>
import DataView from './data-view.vue';
import DataCard from '../../models/DataCard';
import configMixin from '../../plugins/configMixin';
import htmlSanitizer from '../../plugins/htmlSanitizer';

export default {
  components: {
    DataView,
  },
  data() {
    return {};
  },
  mixins: [configMixin, htmlSanitizer],
  props: {
    bans: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inGroupPostpaidCard() {
      const inGroupArray = [];
      Object.values(this.bans).forEach((ban) => {
        ban.ctns.forEach((ctn) => {
          if (!inGroupArray.some(e => e['group-id'] === (ctn.groupInfo ? ctn.groupInfo['group-id'] === e['group-id'] : false)) && !inGroupArray.some(e => e['ban-id'] === ctn['ban-id'])) {
            inGroupArray.push({
              'group-id': ctn.groupInfo ? ctn.groupInfo['group-id'] : '',
              'ban-id': ctn['ban-id'],
              values: ((ctn['group-status'] !== 'N') ? [ctn] : []),
              title: `Számlázási azonosító: ${ctn['ban-id']}`,
            });
          } else if (ctn['group-status'] !== 'N') {
            inGroupArray.find(e => (ctn.groupInfo ? ctn.groupInfo['group-id'] === e['group-id'] : false)).values.push(ctn);
          }
        });
      });
      return inGroupArray;
    },
    outGroupPostpaidCard() {
      const outGroupArray = [];
      Object.values(this.bans).forEach((ban) => {
        ban.ctns.forEach((ctn) => {
          if (!outGroupArray.some(e => e['ban-id'] === ctn['ban-id'])) {
            outGroupArray.push({
              values: ((ctn['group-status'] === 'N') ? [ctn] : []),
              title: `Számlázási azonosító: ${ctn['ban-id']}`,
              'ban-id': ctn['ban-id'],
            });
          } else if (ctn['group-status'] === 'N') {
            outGroupArray.find(e => e['ban-id'] === ctn['ban-id']).values.push(ctn);
          }
        });
      });
      return outGroupArray;
    },
    groups() {
      return {
        inGroup: this.createCards(this.inGroupPostpaidCard, 'Nincs előfizetés a Prime csoportban'),
        outGroup: this.createCards(this.outGroupPostpaidCard, 'A számlázási azonosító összes előfizetése a Prime csoport tagja'),
      };
    },
  },
  methods: {
    createCards(panels, emptyText) {
      return panels.map(p => this.mapPanel(p, emptyText));
    },
    mapPanel(panel, e) {
      return {
        ...panel,
        values: panel.values.map(k => new DataCard(k)),
        emptyText: e,
      };
    },
  },
};
</script>

<style>
/* responsive css here */
@media (max-width: 1170px) {
  .rightside .dash-create-button {
    float: left;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .rightside .dash-big-title {
    display: block;
  }
}

@media (max-width: 1080px) {
  .sub-people-container .sub-box, .sub-people-container .sub-box-inactive-alt {
    width: calc(100% - 20px);
    display: block;
  }

  .sub-people-container .sub-box-inactive {
    width: calc(100% - 20px);
  }

  .subscription-container .sub-people-container {
    flex-wrap: unset;
    display: block;
  }
}

@media (max-width: 860px) {
  .rightside .dash-big-title, .leftside .dash-big-title  {
    font-size: 2.5em;
  }
}

@media (max-width: 655px) {
  .rightside .dash-big-title, .leftside .dash-big-title  {
    font-size: 2em;
  }
}

@media (max-width: 610px) {
  .dasherror-text-div p {
    font-size: 1.8em;
  }
}

@media (max-width: 580px) {
  .dashboard-content .separator {
    display: none;
  }

  .dashboard-content, .group-content-flex {
    display: block;
  }

  .dashboard-content .leftside, .dashboard-content .rightside {
    width: 100%;
    min-width: 275px;
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .rightside .dash-create-button {
    width: calc(100% - 20px);
  }

  .check-list-container span {
    font-size: 0.8em;
  }

  .dialog-title-text {
    font-size: 1.1em;
  }
}

@media (max-width: 330px) {
  .dasherror-text-div p {
    font-size: 1.3em;
  }
}
</style>

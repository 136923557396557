<template>
<div>
  <span class="invite-card-text">Ezt a számot a {{cardText.value.nominatorFormatted}} meghívta a Prime csoportjába.</span>
  <div class="invite-card-links">
    <a @click="cardText.callback(cardText.value, true)">Elfogadás</a>
    <a @click="cardText.callback(cardText.value, false)">Elutasítás</a>
  </div>
</div>
</template>
<script>
export default {
  props: {
    cardText: Object,
  },
};
</script>

<template>
  <data-view
      :showMembers="showMembers"
      :showDesc="showDesc"
      :responseHandler="responseHandler"
      :isCreate="isCreate"
      :inGroupLabel="strictSanitize(config.text.memberlabel)"
      :notInGroupLabel="strictSanitize(config.text.notmemberlabel)"
      :inGroup="panledInGroup"
      :notInGroup="panledNotInGroup"
      >
  <template v-slot:rightSideText>
    <slot name="rightSideText"/>
  </template>
  <template v-slot:righticon>
    <slot name="righticon"/>
  </template>
  <template v-slot:leftTableInfo>
    <div v-html="sanitize(config.text.notmember)" v-if="showDesc" class="sidepanel-desc-container">
    </div>
  </template>
  <template v-slot:rightTableInfo>
    <div class="discount-div" v-if="showDesc">
      <img class="discount-smile-svg" :src="require('../../assets/smile.svg')" contain height="20px"/>
      <slot name="rightSideText"/>
    </div>
    <div class="warning-div" v-if="showDesc">
      <p>{{warningMessage}}</p>
      <slot name="righticon"/>
    </div>
  </template>
  <slot/>
  </data-view>
</template>

<script>
import { mapState } from 'vuex';
import configMixin from '../../plugins/configMixin';
import htmlSanitizer from '../../plugins/htmlSanitizer';
import DataView from './data-view.vue';

export default {
  mixins: [configMixin, htmlSanitizer],
  props: {
    hidePrepaid: Boolean,
    notInGroup: Object,
    inGroup: Object,
    showDesc: {
      type: Boolean,
      default: true,
    },
    showMembers: {
      type: Boolean,
      default: true,
    },
    responseHandler: {
      type: Function,
    },
    isCreate: Boolean,
  },
  computed: {
    panledNotInGroup() {
      const group = this.notInGroup;
      return [
        this.createPanel(group.postPaid, 'Számlás előfizetéseim', this.getEmptyTextOutGroup('Számlás előfizetéseim')),
        this.createPanel(group.internet, 'Otthoni internet', this.getEmptyTextOutGroup('Otthoni internet')),
      ];
    },
    panledInGroup() {
      const group = this.inGroup;
      const groups = [
        this.createPanel(group.postPaid, 'Számlás előfizetéseim', this.getEmptyTextInGroup('Számlás előfizetéseim')),
        this.createPanel(group.internet, 'Otthoni internet', this.getEmptyTextInGroup('Otthoni internet')),
      ];
      return groups;
    },
    ...mapState('multisim', ['inGroupMembers']),
    warningMessage() {
      if (this.isCreate) {
        if (this.inGroupMembers.hiperMembers < 2) {
          return this.strictSanitize(this.config.text.noGroupWarningEmpty);
        }
        return this.strictSanitize(this.config.text.noGroupWarning);
      }
      return this.strictSanitize(this.config.text.GroupWarning);
    },
  },
  methods: {
    getEmptyTextInGroup(label) {
      if (label === 'Számlás előfizetéseim') {
        return 'Nincs számlás előfizetés szolgáltatás a számlázási azonosítóhoz kapcsolva';
      }
      if (label === 'Kártyás előfizetéseim') {
        if (this.inGroupMembers.prepaidMembers === 0) {
          return 'Jelenleg nincs ilyen telefonszám ehhez a számlázási azonosítóhoz kapcsolva';
        }
        return 'Nincs feltöltőkártyás előfizetés a számlázási azonosítóhoz kapcsolva';
      }
      if (this.inGroupMembers.internetMembers === 0) {
        return 'Jelenleg nincs ilyen telefonszám ehhez a számlázási azonosítóhoz kapcsolva';
      }
      return 'Nincs otthoni internet szolgáltatás a számlázási azonosítóhoz kapcsolva';
    },
    getEmptyTextOutGroup(label) {
      if (this.inGroupMembers.hasNotInGroup.postpaid && label === 'Számlás előfizetéseim') {
        return 'Nincs számlás előfizetés szolgáltatás a számlázási azonosítóhoz kapcsolva';
      }
      if (this.inGroupMembers.hasNotInGroup.prepaid && label === 'Kártyás előfizetéseim') {
        return 'Nincs feltöltőkártyás előfizetés a számlázási azonosítóhoz kapcsolva';
      }
      if (this.inGroupMembers.hasNotInGroup.internet && label === 'Otthoni internet') {
        return 'Nincs otthoni internet szolgáltatás a számlázási azonosítóhoz kapcsolva';
      }
      return 'Jelenleg nincs ilyen telefonszám ehhez a számlázási azonosítóhoz kapcsolva';
    },
    createPanel(values, title, emptyText) {
      return {
        values,
        title,
        emptyText,
      };
    },
  },
  components: {
    DataView,
  },
};
</script>

import DOMPurify from 'dompurify';

export default {
  methods: {
    sanitize(string) {
      return DOMPurify.sanitize(string, { ALLOWED_TAGS: ['a', 'b', 'p', 'br'], ALLOWED_ATTR: ['href'] });
    },
    faqSanitize(string) {
      return DOMPurify.sanitize(string, { ALLOWED_TAGS: ['a', 'b', 'p', 'br', 'strong', 'li', 'ul'], ALLOWED_ATTR: ['href'] });
    },
    strictSanitize(string) {
      return DOMPurify.sanitize(string, { ALLOWED_TAGS: ['b'] });
    },
  },
};

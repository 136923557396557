<template>
  <div>overflow: hidden;
    <v-dialog overlay-color="#002340" overlay-opacity="0.8" class="innerscrolldialog" :value="enable" @input="onCancel()" v-if="!failed && !success" max-width="600" :persistent="loading">
      <div class="scrollabledialog">
      <a @click="onCancel()" class="dialog-x-button">
        <img class="gray-x-svg" :src="require('../../../assets/gray-x.svg')" contain height="23px"/>
      </a>
      <v-card class="dialog-content">
        <div class="dialog-full-content">
          <span class="dialog-title-text">A meghívás feltételei szerint a telefonszám:</span>
          <div class="check-list-container">
            <v-icon color="#FFBA0F" class="check-mark-svg">mdi-alert-circle</v-icon>
            <span>Nem lehet tagja más hívócsoportnak</span><br>
            <v-icon color="#FFBA0F" class="check-mark-svg">mdi-alert-circle</v-icon>
            <span>Aktív, lakossági előfizetés</span><br>
            <v-icon color="#FFBA0F" class="check-mark-svg">mdi-alert-circle</v-icon>
            <span>Yettel Feltöltőkártyás vagy Hello Kártyás tarifacsomagban van</span><br>
            <v-icon color="#FFBA0F" class="check-mark-svg">mdi-alert-circle</v-icon>
            <span>Egyenlegéből levonható az 1390 Ft időszaki díj</span><br>
          </div>
        </div>
      </v-card>
      <v-form v-model="invitable">
        <div>
          <div>
            <div class="dialog-gray-input-full-container">
              <h2 class="dialog-gray-input-title">Meghívott telefonszáma</h2>
              <div class="dialog-gray-input-container">
                <img class="input-phone-svg" :src="require('../../../assets/phone.svg')" contain height="15px"/>
                <v-autocomplete
                  v-model="selectedPrefix"
                  :items="prefixList"
                  class="dialog-gray-combobox"
                ></v-autocomplete>
                <div class="dialog-input-separator"></div>
                <v-text-field v-mask="mask" :rules="phoneRules" required type="text" minlength="8" maxlength="8" v-model="prepaidNumber" class="dialog-gray-input dialogvinput" placeholder="000 0000"/>
              </div>
            </div>
            <div class="dialog-gray-input-full-container">
              <h2 class="dialog-gray-input-title">Az én számom</h2>
              <div class="dialog-gray-input-container">
                <img class="input-phone-svg" :src="require('../../../assets/phone.svg')" contain height="15px"/>
                <v-select
                  v-if="mobile"
                  v-model="selectedPostpaid"
                  :items="hiperNumbers"
                  class="dialog-gray-combobox-full"
                ></v-select>
                <v-autocomplete
                  v-else
                  v-model="selectedPostpaid"
                  :items="hiperNumbers"
                  class="dialog-gray-combobox-full"
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-buttons">
          <v-btn :disabled="!invitable || loading" class="dialog-green-button" text @click="ok">Meghívás
            <img class="loading-animation dialog" v-if="loading" :src="require('../../../assets/loader-gray.gif')" contain height="15px"/>
            </v-btn>
        </div>
      </v-form>
      </div>
    </v-dialog>


      <error-dialog :failed="failed" :onCancel="onCancel" title="A meghívás sikertelen, mert a meghívás feltételei nem teljesülnek!" :failedMessage="errorDetail"/>
      <success-dialog :success="success" :onCancel="onCancel" title="A meghívót sikeresen elküldtük!" text="Ahhoz, hogy a telefonszám a Prime csoport tagja legyen, a meghívottnak el kell fogadnia a meghívást. "/>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { isMobile } from 'mobile-device-detect';
import errorDialog from './error-dialog.vue';
import successDialog from './success-dialog.vue';

export default {
  directives: {
    mask,
  },
  components: {
    errorDialog,
    successDialog,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Function,
    },
    submit: {
      type: Function,
    },
    hiperNumbers: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    errorDetail: String,
  },
  methods: {
    onCancel() {
      if (!this.loading) {
        this.cancel();
      }
    },
    ok() {
      this.submit(`${this.selectedPrefix}${this.prepaidNumber.replace(' ', '')}`, this.selectedPostpaid);
    },
  },
  watch: {
    hiperNumbers() {
      if (this.hiperNumbers) {
        this.selectedPostpaid = this.hiperNumbers[0];
      }
    },
  },
  created() {
    if (this.hiperNumbers) {
      this.selectedPostpaid = this.hiperNumbers[0];
    }
  },
  data() {
    return {
      mask: '### ####',
      invitable: false,
      errorDialog: false,
      prefixList: ['+36 20', '+36 30', '+36 31', '+36 50', '+36 70'],
      prepaidNumber: '',
      phoneRules: [
        v => !!v || 'A telefonszám megadása kötelező',
        v => (v && v.length === 8) || 'Legalább 7 karakter megadása kötelező',

      ],
      selectedPostpaid: '+36 20 123 4567',
      selectedPrefix: '+36 20',
      mobile: isMobile,
    };
  },
};
</script>

<style>
/* dialog css */
/* dialog css */
.dialog-gray-input.dialogvinput {
  display: inline-block;
  width: calc(100% - 150px);
}

.dialog-gray-input.dialogvinput .v-input__control .v-input__slot::before, .dialog-gray-input.dialogvinput .v-input__control .v-input__slot::after {
  display: none;
}

.dialog-gray-input.dialogvinput .v-input__control .v-text-field__details {
  display: none;
}

.dialog-content.v-card.v-sheet.theme--light {
  background-color: #ffffff00;
  pointer-events: none;
}

.v-dialog {
  border-radius: 12px !IMPORTANT;
}

.dialog-x-button {
  float: right;
  width: 23px !IMPORTANT;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer
}

.prepaid-dialog-text.upper.text {
  font-size: 0.875em;
  text-align: center;
  font-family: yettel,sans-serif,arial;
  color: #002340;
  max-width: 500px;
  display: block;
  margin: auto;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.prepaid-dialog-text.upper.middle {
  font-size: 0.875em;
  text-align: center;
  font-family: yettel,sans-serif,arial;
  color: #002340;
  max-width: 500px;
  display: block;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.prepaid-dialog-text2.bottom.text {
  color: #666666;
  font-size: 0.875em;
  text-align: center;
  font-family: yettel,sans-serif,arial;
  max-width: 500px;
  display: block;
  margin: auto;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.dialog-title-text {
  font-size: 1.3125em;
  font-family: yettel,sans-serif,arial;
  color: #002340;
  text-align: center;
  display: block;
  max-width: calc(100% - 120px);
  margin: auto;
  margin-top: 20px;
}

.dialog-title-text b {
  white-space: nowrap;
  font-weight: bold;
}

.red-error-svg {
  margin: auto;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
}

.dialog-content {
  max-width: 741px;
  /*padding: 15px;*/
  box-shadow: none !IMPORTANT;
  border-radius: 0px !IMPORTANT;
}

.dialog-buttons {
  background-color: white;
  display: flex;
  height: 70px;
  margin-top: 20px;
}

.v-btn.dialog-green-button {
  margin: auto;
  min-width: 217px !IMPORTANT;
  max-width: 265px;
  height: 50px !IMPORTANT;
  background-color: #002340;
  color: white !IMPORTANT;
  border-radius: 24px;
  font-family: yettel_bold,sans-serif,arial;
  margin-top: 0px;
  outline: none;
  text-transform: none !important;
}

.v-btn.dialog-green-button:hover {
  cursor: pointer;
  background: #01385E;
}

.v-btn.dialog-red-button {
  margin: auto;
  width: 217px;
  height: 50px !IMPORTANT;
  background-color: #002340;
  color: white !IMPORTANT;
  border-radius: 24px;
  font-family: yettel_bold,sans-serif,arial;
  margin-top: 0px;
  outline: none;
  text-transform: none !important;
}

.v-btn.dialog-red-button:hover {
  cursor: pointer;
  background: #01385E;
}

.v-btn.dialog-gray-button {
  margin: auto;
  width: 217px;
  height: 50px !IMPORTANT;
  background-color: #FFFFFF;
  border: 2px solid #002340;
  text-transform: none !important;
  color: #002340 !IMPORTANT;
  border-radius: 24px;
  font-family: yettel_bold,sans-serif,arial;
  margin-top: 0px;
  outline: none;
}

.v-btn.dialog-gray-button:hover {
  cursor: pointer;
  background: #bdc7cc;
}

.check-mark-svg {
  margin-right: 10px;
  margin-bottom: 7px;
  color: #FFBA0F;
}

.check-list-container {
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  padding-left: 20px;
  font-family: yettel_light,sans-serif,arial;
  color: #002340;
}

.check-list-container span {
  vertical-align: top;
}

.dialog-gray-combobox .v-input__control .v-input__slot::before, .dialog-gray-combobox .v-input__control .v-input__slot::after, .dialog-gray-combobox-full .v-input__control .v-input__slot::before, .dialog-gray-combobox-full .v-input__control .v-input__slot::after {
  display: none;
}

.dialog-gray-input {
  height: 34px;
  font-family: yettel,sans-serif,arial;
  vertical-align: top;
  font-size: 0.8125em !IMPORTANT;
  outline: none;
  padding-left: 15px;
  padding-top: 2px !IMPORTANT;
  width: calc(100% - 120px) !IMPORTANT;
}

.dialog-gray-input::placeholder {
  color: #B0B0B0;
}

.dialog-gray-input-container {
  background: #FFFFFF;
  border: 2px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.dialog-gray-input-full-container {
  max-width: 360px;
  margin: auto;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.dialog-gray-input-title {
  font-size: 0.8125em;
  color: #002340;
  font-family: yettel,sans-serif,arial;
  font-weight: normal;
  margin-bottom: 10px;
}

.input-phone-svg {
  margin: 0;
  vertical-align: middle;
  margin-top: 13px;
  margin-left: 15px;
  vertical-align: top;
}

.dialog-input-separator {
  height: 39px;
  width: 1px;
  background-color: rgba(209, 209, 209, 0.5);
  border-top: 5px solid white;
  border-bottom: 5px solid white;
  display: inline-block;
  vertical-align: middle;
}

.dialog-red-button.v-btn.v-btn--disabled, .dialog-gray-button.v-btn.v-btn--disabled, .dialog-green-button.v-btn.v-btn--disabled   {
  background-color: #E5E9EC !important;
  color: #80919F !important;
  border: none !important;
}

.loading-animation.dialog {
  margin-left: 10px;
}

.dialog-title-text.bold {
  font-family: yettel_bold;
}
/* dialog css */

@media (max-width: 580px) {
  .dialog-buttons {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .dialog-gray-input-full-container {
    max-width: calc(100% - 20px);
  }

  .v-btn.dialog-gray-button, .v-btn.dialog-green-button, .v-btn.dialog-red-button {
    width: calc(100% - 40px);
    max-width: 100% !IMPORTANT;
    display: block;
    margin-bottom: 20px;
  }
}


.dialog-gray-combobox {
  height: 34px;
  font-family: yettel,sans-serif,arial;
  padding-left: 10px;
  font-size: 0.8125em !IMPORTANT;
  padding-top: 2px !IMPORTANT;
  width: 85px;
  display: inline-block !IMPORTANT;
  color: #666666;
}

.dialog-gray-combobox-full {
  height: 41px;
  font-family: yettel,sans-serif,arial;
  padding-left: 10px;
  font-size: 0.8125em !IMPORTANT;
  padding-top: 5px !IMPORTANT;
  margin-top: 0px !IMPORTANT;
  width: calc(100% - 40px);
  display: inline-block !IMPORTANT;
  color: #666666;
}

.scrollabledialog {
  max-height: 90vh;
  overflow-y: auto;
}
</style>

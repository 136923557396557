<template>
  <div class="sub-box-inactive">
    <div class="sub-box-header" v-if="value['price-plan-description']">
      <h3></h3>
      <span class="c-default white">{{value['price-plan-description']}}</span>
    </div>
    <div class="empty-sub-box-header" v-if="!value['price-plan-description']"></div>
    <div class="sub-box-empty-message">
      <p>{{value.message}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
};
</script>
<style scoped>
.white {
  background-color: #ffffff;
}
</style>

<template>
  <data-view inGroupLabel="Prime csoport"
             notInGroupLabel="Kártyás előfizetéseim"
             :inGroup="groups.inGroup"
             :notInGroup="groups.outGroup"
              >
    <template v-slot:emptyInGroup>
      <p class="information-message nosub">Nincs feltöltőkártyás előfizetés a Prime csoportban.</p>
    </template>
    <template v-slot:emptyNotInGroup>
      <p class="information-message nosub">Az összes feltöltőkártyás előfizetés Prime csoport tagja.</p>
    </template>
    <template v-slot:leftTableInfo>
      <div class="sidepanel-desc-container" v-html="sanitize(config.text.viewPrepaidOutGroup)"></div>
    </template>
    <template v-slot:rightTableInfo>
      <div class="sidepanel-desc-container" v-html="sanitize(config.text.viewPrepaidInGroup)"></div>
    </template>

    <confirm-dialog v-if="this.delete" :enable="this.delete" :cancel="onCancel"
                    :submit="deletePrepaid" :success="success" :loading="loading"
                    :failed="failed" successMessage="Az előfizetést eltávolítottad a Prime csoportból"
                    errorMessage="Hiba történt!<br>Az előfizetést nem sikerült eltávolítani a csoportból."
                    :errorDetail="failedMessage" okButtonText="Törlés" buttonColor="red">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/red-error.svg')" contain height="58px"/>
                    </template>
      Biztos, hogy szeretnéd törölni a <b>{{phoneNumber}}</b> telefonszámot a csoportból?
    </confirm-dialog>

    <confirm-dialog v-if="acceptPrepaidInv" :enable="acceptPrepaidInv" :cancel="onCancel"
                    :submit="acceptInvitePrepaid" :success="success" :loading="loading"
                    :failed="failed" successMessage="Az előfizetést hozzáadtad a Prime csoporthoz"
                    errorMessage="A csoporthoz adás sikertelen!" :errorDetail="failedMessage" okButtonText="Elfogadás" buttonColor="green">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/green-ok.svg')" contain height="58px"/>
                    </template>
      A <b>{{phoneNumber}}</b> telefonszámot meghívták a Prime csoportba. Elfogadod?
      <template v-slot:bottom>
        <p class="prepaid-dialog-text">A meghívás elfogadásával időszaki díjat vonunk le az egyenlegedből.<br>A szolgáltatás díja 1390Ft / 30 nap.</p>
      </template>
    </confirm-dialog>

    <confirm-dialog v-if="deletePrepaidInv" :enable="deletePrepaidInv" :cancel="onCancel"
                    :submit="deleteInvitePrepaid" :success="false" :loading="loading"
                    :failed="failed" errorMessage="A meghívó elutasítása sikertelen." okButtonText="Elutasítás" buttonColor="red">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/red-error.svg')" contain height="58px"/>
                    </template>
      Biztosan elutasítod a meghívást?
    </confirm-dialog>

  </data-view>
</template>

<script>
import DataView from './data-view.vue';
import DataCard from '../../models/DataCard';
import viewMixin from '../../plugins/viewMixin';
import configMixin from '../../plugins/configMixin';
import htmlSanitizer from '../../plugins/htmlSanitizer';
import MultisimModule from '../../modules/MultisimModule';
import confirmDialog from './dialogs/confirm-dialog.vue';

export default {
  mixins: [
    viewMixin,
    configMixin,
    htmlSanitizer,
  ],
  components: {
    DataView,
    confirmDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      failed: false,
      success: false,
      selectedCTN: {},
      dialogCallback() {},
      delete: false,
      invPrepaidDialog: false,
      postpaidAdd: false,
      prepaidAdd: false,
      failedMessage: '',
      acceptPrepaidInv: false,
      deletePrepaidInv: false,
      revokePrepaidInv: false,
    };
  },
  props: {
    prepaids: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inGroupPrepaidCard() {
      const inGroupArray = [];
      Object.values(this.prepaids).forEach((element) => {
        if (element.groupInfo && element.groupInfo.type === '411') {
          const index = inGroupArray.findIndex(arrayEl => arrayEl['group-id'] === element.groupInfo['group-id']);
          if (index === -1) {
            inGroupArray.push({
              'group-id': element.groupInfo['group-id'],
              values: [{ ...element, colorClass: element.groupInfo['group-status'] === 'M' ? ' blue' : element.colorClass, isGray: element.groupInfo['group-status'] === 'I' ? ' gray' : element.isGray }],
              title: `${inGroupArray.length + 1}. Prime csoport`,
            });
          }
          if (index !== -1) {
            inGroupArray[index].values.push({ ...element, colorClass: element.groupInfo['group-status'] === 'M' ? ' blue' : element.colorClass, isGray: element.groupInfo['group-status'] === 'I' ? ' gray' : element.isGray });
          }
        }
      });
      if (inGroupArray.length === 1) {
        inGroupArray[0].title = 'Prime csoport tagság';
      }
      return inGroupArray;
    },
    outGroupPrepaidCard() {
      const outGroupArray = [];
      Object.values(this.prepaids).forEach((element) => {
        if (!element.groupInfo || element.groupInfo.type !== '411') {
          outGroupArray.push(element);
        }
      });

      return [{
        title: 'Feltöltőkártyás előfizetéseim',
        values: outGroupArray,
      }];
    },
    groups() {
      return {
        inGroup: this.createCards(this.inGroupPrepaidCard, 'Nincs feltöltőkártyás előfizetés a Prime csoportban', this.getCardText),
        outGroup: this.createCards(this.outGroupPrepaidCard, 'Az összes feltöltőkártyás előfizetés Prime csoport tagja.'),
      };
    },
  },
  methods: {
    handleMultisimResponse(resp) {
      const banid = resp['multisim-group-info']['ban-id'];
      if (this.bans[banid]) {
        this.storeNewBan(resp);
      } else {
        const respCTNlist = resp['multisim-member-list'].filter(k => k.ctn === this.selectedCTN.ctn);
        if (respCTNlist.length) {
          const [respCTN] = respCTNlist;
          this.handleAddPrepaid(respCTN, resp['multisim-group-info']);
        } else {
          this.handleRemovePrepaid(this.selectedCTN);
        }
      }
    },
    deleteInvitePrepaid() {
      this.loading = true;
      MultisimModule.deleteInvitePrepaid(this.selectedCTN.ctn).then((resp) => {
        this.success = true;
        this.handleMultisimResponse(resp);
        this.pushPrepaidInvite(this.revokePrepaidInv ? 'revoke' : 'reject', null, this.getEricaData(this.selectedCTN.ctn, null, true, this.selectedCTN.groupInfo['group-id']));
        this.deletePrepaidInv = false;
        this.$forceUpdate();
      }).catch((err) => {
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushPrepaidInvite(this.revokePrepaidInv ? 'revoke' : 'reject', err, this.getEricaData(this.selectedCTN.ctn, null, true, this.selectedCTN.groupInfo['group-id']));
        this.failed = true;
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    acceptInvitePrepaid() {
      this.loading = true;
      MultisimModule.acceptInvitePrepaid(this.selectedCTN.ctn).then((resp) => {
        this.success = true;
        this.pushPrepaidInvite('confirm', null, this.getEricaData(this.selectedCTN.ctn, null, true, this.selectedCTN.groupInfo['group-id']));
        this.handleMultisimResponse(resp);
        this.$forceUpdate();
      }).catch((err) => {
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushPrepaidInvite('confirm', err, this.getEricaData(this.selectedCTN.ctn, null, true, this.selectedCTN.groupInfo['group-id']));
        this.failed = true;
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    deletePrepaid() {
      this.loading = true;
      MultisimModule.deletePrepaid(this.selectedCTN.groupInfo.banid, this.selectedCTN.ctn).then((resp) => {
        this.handleMultisimResponse(resp);
        this.pushAddRemoveCtn(this.selectedCTN, false, null, this.getEricaData(this.selectedCTN.ctn, null, true, this.selectedCTN.groupInfo['group-id']));
        this.success = true;
        this.$forceUpdate();
      }).catch((err) => {
        this.failed = true;
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushAddRemoveCtn(this.selectedCTN, false, err, this.getEricaData(this.selectedCTN.ctn, null, true, this.selectedCTN.groupInfo['group-id']));
        // console.log(err);
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    onCancel() {
      this.delete = false;
      this.acceptPrepaidInv = false;
      this.deletePrepaidInv = false;
      this.revokePrepaidInv = false;
      this.postpaidAdd = false;
      this.prepaidAdd = false;
      this.failed = false;
      this.success = false;
      this.invPrepaidDialog = false;
      this.failedMessage = '';
    },
    removeCtn(ctn) {
      this.selectedCTN = ctn;
      this.delete = true;
    },
    getCardText(ctn) {
      const methodChain = [
        this.suspendedPrepaidLink,
        this.invitedPrepaid(ctn, this.prepaidInviteClick),
        this.produceRemoveAction(ctn, this.removeCtn),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    prepaidInviteClick(ctn, accept, revoke) {
      this.selectedCTN = ctn;
      this.acceptPrepaidInv = accept && !revoke;
      this.deletePrepaidInv = !accept && !revoke;
      this.revokePrepaidInv = !accept && revoke;
    },
    createCards(panels, emptyText, ctSuplier) {
      return panels.map(k => this.mapPanel(k, ctSuplier, emptyText));
    },
    mapPanel(panel, ctSuplier, e) {
      return {
        ...panel,
        values: panel.values.map(k => new DataCard(k, ctSuplier && ctSuplier(k))),
        emptyText: e,
      };
    },
  },
};
</script>

<style>
/* responsive css here */
@media (max-width: 1170px) {
  .rightside .dash-create-button {
    float: left;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .rightside .dash-big-title {
    display: block;
  }
}

@media (max-width: 1080px) {
  .sub-people-container .sub-box, .sub-people-container .sub-box-inactive-alt {
    width: calc(100% - 20px);
    display: block;
  }

  .sub-people-container .sub-box-inactive {
    width: calc(100% - 20px);
  }

  .subscription-container .sub-people-container {
    flex-wrap: unset;
    display: block;
  }
}

@media (max-width: 860px) {
  .rightside .dash-big-title, .leftside .dash-big-title  {
    font-size: 2.5em;
  }
}

@media (max-width: 655px) {
  .rightside .dash-big-title, .leftside .dash-big-title  {
    font-size: 2em;
  }
}

@media (max-width: 610px) {
  .dasherror-text-div p {
    font-size: 1.8em;
  }
}

@media (max-width: 580px) {
  .dashboard-content .separator {
    display: none;
  }

  .dashboard-content, .group-content-flex {
    display: block;
  }

  .dashboard-content .leftside, .dashboard-content .rightside {
    width: 100%;
    min-width: 275px;
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .rightside .dash-create-button {
    width: calc(100% - 20px);
  }

  .check-list-container span {
    font-size: 0.8em;
  }

  .dialog-title-text {
    font-size: 1.1em;
  }
}

@media (max-width: 330px) {
  .dasherror-text-div p {
    font-size: 1.3em;
  }
}
</style>

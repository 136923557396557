<template>
  <group-view :inGroup="filledGroups.inGroup"
  :notInGroup="filledGroups.notInGroup"
  :responseHandler="ctnClicked">
  <template v-slot:rightSideText>
      <p v-if="inGroupMembers.hiperMembers === 2">{{strictSanitize(config.text.twoDiscount)}}</p>
      <p v-if="inGroupMembers.hiperMembers > 2">{{strictSanitize(config.text.threeDiscount)}}</p>
  </template>
  <template v-slot:righticon>
          <img class="okay-svg" :src="require('../../assets/gray-okay.svg')" contain height="15px">
  </template>

    <add-prepaid-dialog v-if="prepaidAdd" :enable="prepaidAdd" :cancel="onCancel"
                    :submit="addPrepaid" :success="success" :loading="loading" :failed="failed"
                    :phoneNumber="phoneNumber" :errorDetail="failedMessage" :nominators="hiperNumbers"/>

    <confirm-dialog v-if="postpaidAdd" :enable="postpaidAdd" :cancel="onCancel"
                    :submit="addPostpaid" :success="success" :loading="loading"
                    :failed="failed" successMessage="A csoporthoz adás sikeres"
                    errorMessage="A csoporthoz adás sikertelen" :errorDetail="failedMessage" okButtonText="Hozzáadás" buttonColor="green">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/green-ok.svg')" contain height="58px"/>
                    </template>
      Biztos, hogy hozzá szeretnéd adni a <b>{{phoneNumber}}</b> telefonszámot a csoporthoz?
    </confirm-dialog>

    <confirm-dialog v-if="this.delete" :enable="this.delete" :cancel="onCancel"
                    :submit="dialogCallback" :success="success" :loading="loading"
                    :failed="failed" successMessage="Az előfizetést eltávolítottad a Prime csoportból"
                    errorMessage="Hiba történt!<br>Az előfizetést nem sikerült eltávolítani a csoportból."
                    :errorDetail="failedMessage" okButtonText="Törlés" buttonColor="red">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/red-error.svg')" contain height="58px"/>
                    </template>
      Biztos, hogy szeretnéd törölni a <b>{{phoneNumber}}</b> telefonszámot a csoportból?
    </confirm-dialog>

    <confirm-dialog v-if="acceptPrepaidInv" :enable="acceptPrepaidInv" :cancel="onCancel"
                    :submit="acceptInvitePrepaid" :success="success" :loading="loading"
                    :failed="failed" successMessage="Az előfizetést hozzáadtad a Prime csoporthoz"
                    errorMessage="A csoporthoz adás sikertelen!" :errorDetail="failedMessage" okButtonText="Elfogadás" buttonColor="green">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/green-ok.svg')" contain height="58px"/>
                    </template>
      A <b>{{phoneNumber}}</b> telefonszámot meghívták a Prime csoportba. Elfogadod?
      <template v-slot:bottom>
        <p class="prepaid-dialog-text">A meghívás elfogadásával időszaki díjat vonunk le az egyenlegedből.<br>A szolgáltatás díja 1390Ft / 30 nap.</p>
      </template>
    </confirm-dialog>

    <confirm-dialog v-if="deletePrepaidInv" :enable="deletePrepaidInv" :cancel="onCancel"
                    :submit="deleteInvitePrepaid" :success="false" :loading="loading"
                    :failed="failed" errorMessage="A meghívó elutasítása sikertelen." okButtonText="Elutasítás" buttonColor="red">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/red-error.svg')" contain height="58px"/>
                    </template>
      Biztosan elutasítod a meghívást?
    </confirm-dialog>

    <confirm-dialog v-if="revokePrepaidInv" :enable="revokePrepaidInv" :cancel="onCancel"
                    :submit="deleteInvitePrepaid" :success="success" :loading="loading"
                    :failed="failed" errorMessage="Hiba történt! A meghívót nem sikerült visszavonni."
                    successMessage="Sikeresen visszavontad a Prime csoport meghívódat" okButtonText="Visszavonás" buttonColor="red">
                    <template v-slot:icon>
                      <img class="red-error-svg" :src="require('../../assets/red-error.svg')" contain height="58px"/>
                    </template>
      Biztos, hogy szeretnéd a meghívót visszavonni?
    </confirm-dialog>

    <invitePrepaidDialog v-if="invPrepaidDialog" :enable="invPrepaidDialog" :cancel="onCancel"
                        :submit="invitePrepaid" :success="success" :loading="loading" :failed="failed"
                        :hiperNumbers="hiperNumbers"/>
  </group-view>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import GroupView from './group-view.vue';
import groupMixin from '../../plugins/groupMixin';
import MultisimModule from '../../modules/MultisimModule';
import invitePrepaidDialog from './dialogs/invite-prepaid-dialog.vue';
import confirmDialog from './dialogs/confirm-dialog.vue';
import addPrepaidDialog from './dialogs/add-prepaid-dialog.vue';
import htmlSanitizer from '../../plugins/htmlSanitizer';


export default {
  mixins: [groupMixin, htmlSanitizer],
  data() {
    return {
      dialog: false,
      loading: false,
      failed: false,
      success: false,
      selectedCTN: {},
      dialogCallback() {},
      delete: false,
      invPrepaidDialog: false,
      postpaidAdd: false,
      prepaidAdd: false,
      failedMessage: '',
      acceptPrepaidInv: false,
      deletePrepaidInv: false,
      revokePrepaidInv: false,
    };
  },
  components: {
    addPrepaidDialog,
    invitePrepaidDialog,
    confirmDialog,
    GroupView,
  },
  computed: {
    ...mapState('multisim', ['inGroupMembers']),
    hiperNumbers() {
      return this.groups.inGroup.postPaid.filter(ctn => ctn.value.isHiper && ctn.value['group-status'] === 'M').map(ctn => this.formatCtn(ctn.value.ctn));
    },
    phoneNumber() {
      return this.formatCtn(this.selectedCTN.ctn);
    },
    groupId() {
      return this.bans[this.selectedBan]['multisim-group-info']['group-id'];
    },

  },
  methods: {

    textMemberPrep(ctn) {
      const methodChain = [
        this.suspendedPrepaidLink,
        this.invitedPrepaid(ctn, this.prepaidInviteClick),
        this.produceRemoveAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textMemberPost(ctn) {
      const methodChain = [
        this.postDeleteEnabled,
        this.produceRemoveAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textMemberInternet(ctn) {
      const methodChain = [
        this.produceRemoveAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textNotMemberPrep(ctn) {
      const errorChain = [
        this.isTariffAddable,
        this.isActive,
      ];
      const methodChain = [
        this.createLimitLink(this.inGroupMembers.prepaidMembers, this.prepaidLimit),
        this.getErrorsLink(errorChain, ctn),
        this.produceAddAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textNotMemberPost(ctn) {
      const errorChain = [
        this.isTariffAddable,
        this.isCommitEndDateValid,
        this.isActive,
      ];
      const methodChain = [
        this.createLimitLink(this.inGroupMembers.postpaidMembers, this.postpaidLimit),
        this.getErrorsLink(errorChain, ctn),
        this.produceAddAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    textNotMemberInternet(ctn) {
      const errorChain = [
        this.isCommitEndDateValid,
        this.isTariffAddable,
        this.isActive,
      ];
      const methodChain = [
        this.createLimitLink(this.inGroupMembers.internetMembers, this.internetLimit),
        this.getErrorsLink(errorChain, ctn),
        this.produceAddAction(ctn, this.ctnClicked),
      ];
      return this.evaulateChain(methodChain, ctn);
    },
    postDeleteEnabled(ctn) {
      if (this.inGroupMembers.hiperMembers <= 2
        && ctn.isHiper) {
        return this.createDisableText(ctn, 'Yettel Prime vagy Yettel M Talk tarifacsomag nem törölhető, mert a csoport feltétele, hogy legalább 2 db legyen benne.');
      }
      return null;
    },
    formatCtn(ctn) {
      const a = ctn;
      if (a) {
        return `+36 ${a.slice(0, 2)} ${a.slice(2, 5)} ${a.slice(5, 9)}`;
      }
      return '';
    },

    onCancel() {
      this.delete = false;
      this.acceptPrepaidInv = false;
      this.deletePrepaidInv = false;
      this.revokePrepaidInv = false;
      this.postpaidAdd = false;
      this.prepaidAdd = false;
      this.failed = false;
      this.success = false;
      this.invPrepaidDialog = false;
      this.failedMessage = '';
    },
    ctnClicked(ctn) {
      const isAdd = ctn['group-status'] !== 'M';
      const isPrepaid = ctn['product-code'] === 'PREP';
      this.selectedCTN = ctn;
      if (isPrepaid) {
        if (isAdd) {
          this.prepaidAdd = true;
          this.dialogCallback = this.addPrepaid;
        } else {
          this.delete = true;
          this.dialogCallback = this.deletePrepaid;
        }
      }
      if (!isPrepaid) {
        if (isAdd) {
          this.postpaidAdd = true;
          this.dialogCallback = this.addPostpaid;
        } else {
          this.delete = true;
          this.dialogCallback = this.deletePostpaid;
        }
      }
    },
    invitePrepaidClicked() {
      this.invPrepaidDialog = true;
    },
    deletePostpaid() {
      this.loading = true;
      MultisimModule.deletePostpaid(this.selectedBan, this.selectedCTN.ctn).then((resp) => {
        this.success = true;
        if (window.innerWidth <= 580) {
          this.$vuetify.goTo('#elofizeteseim');
        }
        this.pushAddRemoveCtn(this.selectedCTN, false, null, this.getEricaData(this.selectedCTN.ctn, null, false, this.groupId));
        this.responseHandler(resp);
      }).catch((err) => {
        this.failed = true;
        this.pushAddRemoveCtn(this.selectedCTN, false, err, this.getEricaData(this.selectedCTN.ctn, null, false, this.groupId));
        // console.log(err);
        this.failedMessage = this.mapError(err.response.data.code);
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    addPostpaid() {
      this.loading = true;
      MultisimModule.addPostpaid(this.selectedBan, this.selectedCTN.ctn).then((resp) => {
        this.success = true;
        if (window.innerWidth <= 580) {
          this.$vuetify.goTo('#hipercsoport');
        }
        this.pushAddRemoveCtn(this.selectedCTN, true, null, this.getEricaData(this.selectedCTN.ctn, null, false, this.groupId));
        this.responseHandler(resp);
      }).catch((err) => {
        // console.log(err);
        this.pushAddRemoveCtn(this.selectedCTN, true, err, this.getEricaData(this.selectedCTN.ctn, null, false, this.groupId));
        this.failed = true;
        this.failedMessage = this.mapError(err.response.data.code);
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    deletePrepaid() {
      this.loading = true;
      MultisimModule.deletePrepaid(this.selectedBan, this.selectedCTN.ctn).then((resp) => {
        this.responseHandler(resp);
        this.pushAddRemoveCtn(this.selectedCTN, false, null, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        this.success = true;
        if (window.innerWidth <= 580) {
          this.$vuetify.goTo('#elofizeteseim');
        }
      }).catch((err) => {
        this.failed = true;
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushAddRemoveCtn(this.selectedCTN, false, err, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        // console.log(err);
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    addPrepaid(nominator) {
      const postp = _.replace(nominator, '+36 ', '').split(' ').join('');
      this.loading = true;
      MultisimModule.addPrepaid(this.selectedBan, this.selectedCTN.ctn, postp).then((resp) => {
        this.success = true;
        if (window.innerWidth <= 580) {
          this.$vuetify.goTo('#hipercsoport');
        }
        this.responseHandler(resp);
        this.pushAddRemoveCtn(this.selectedCTN, true, null, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
      }).catch((err) => {
        // console.log(err);
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushAddRemoveCtn(this.selectedCTN, false, err, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        this.failed = true;
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    acceptInvitePrepaid() {
      this.loading = true;
      MultisimModule.acceptInvitePrepaid(this.selectedCTN.ctn).then((resp) => {
        this.success = true;
        this.pushPrepaidInvite('confirm', null, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        this.responseHandler(resp);
      }).catch((err) => {
        // console.log(err);
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushPrepaidInvite('confirm', err, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        this.failed = true;
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    deleteInvitePrepaid() {
      this.loading = true;
      MultisimModule.deleteInvitePrepaid(this.selectedCTN.ctn).then((resp) => {
        this.responseHandler(resp);
        this.pushPrepaidInvite(this.revokePrepaidInv ? 'revoke' : 'reject', null, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        this.success = this.revokePrepaidInv;
        this.deletePrepaidInv = false;
      }).catch((err) => {
        // console.log(err);
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushPrepaidInvite(this.revokePrepaidInv ? 'revoke' : 'reject', err, this.getEricaData(this.selectedCTN.ctn, null, true, this.groupId));
        this.failed = true;
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    prepaidInviteClick(ctn, accept, revoke) {
      this.selectedCTN = ctn;
      this.acceptPrepaidInv = accept && !revoke;
      this.deletePrepaidInv = !accept && !revoke;
      this.revokePrepaidInv = !accept && revoke;
    },
    invitePrepaid(prepaidCTN, nominator) {
      const postp = _.replace(nominator, '+36 ', '').split(' ').join('');
      const prep = _.replace(prepaidCTN, '+36 ', '').split(' ').join('');
      this.loading = true;
      MultisimModule.invitePrepaid(this.selectedBan, prep, postp).then((resp) => {
        this.success = true;
        if (window.innerWidth <= 580) {
          this.$vuetify.goTo('#hipercsoport');
        }
        this.pushPrepaidInvite('invite', null, this.getEricaData(prep, null, true, this.groupId));
        this.responseHandler(resp);
      }).catch((err) => {
        // console.log(err);
        this.failedMessage = this.mapError(err.response.data.code);
        this.pushPrepaidInvite('invite', err, this.getEricaData(prep, null, true, this.groupId));
        this.failed = true;
        this.handleError(err);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.prepaid-dialog-text {
  font-size: 0.875em;
  text-align: center;
  font-family: yettel,sans-serif,arial;
  color: #002340;
  max-width: 500px;
  display: block;
  margin: auto;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
